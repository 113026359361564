.animate__logo {
    position: relative;
    width: 375px;
    height: 812px;
    /* border: 2px solid #292924; */
    margin: 20px auto;
    overflow: hidden;
}

.animate__logo .logo__animate__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 42px 42px;
    display: flex;
    align-items: center;
    gap: 20px;
    transition: 0.2s;
    animation: log__ani 1.5s ease-in-out;
    width: 261px;
    overflow: hidden;
}

.animate__logo .logo__animate__content.active__scale {
    transform: translate(-50%, -50%) scale(15);
    opacity: 0;
    pointer-events: none;
}

.animate__logo .logo__animate__content .logo__ani {
    animation: scale__logo 1s linear;
    transform: scale(1);
    opacity: 1;
}

.animate__logo .logo__animate__content .text__ani {
    position: relative;
    width: max-content;
    height: 31px;
    object-fit: cover;
    object-position: right;
}

@keyframes log__ani {
    0% {
        top: -10%;
        width: 84px;
    }

    30% {
        top: 65%;
    }

    50% {
        top: 47%;
    }

    60% {
        top: 52%;
    }

    70% {
        top: 50%;
        width: 84px;
    }

    75% {
        width: 84px;
    }

    100% {
        width: 261px;
    }
}