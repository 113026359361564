.container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    inset: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    .modal {
        border-radius: 1rem;
        width: 80%;
        border-bottom: 1px solid var(--Colors-stroke, #e3e3e3);
        background: var(--Colors-tapbar, #fff);
    }
}
