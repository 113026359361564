:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --xdddd: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --grayf6f6f6-474747: #f6f6f6;
    --gray8A8A8E-B3B3B3: #8a8a8e;
}

:root[data-theme='dark'] {
    --background-color: #1c1c1c;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --xdddd: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --grayf6f6f6-474747: #474747;
    --gray8A8A8E-B3B3B3: #b3b3b3;
}

.raceBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.raceTicket {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 1rem; */
    align-self: stretch;

    background: var(--xdddd);
}

.raceName {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    align-self: stretch;

    margin-bottom: 0.5rem;
}

.raceNameUPD {
    display: flex;
    align-items: center;
    gap: 0.375rem;
}

.raceNameTxt {
    flex: 1 0 0;

    color: var(--txt-color);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
}

.raceDetails {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;
    flex: 1 0 0;
}

.raceAuto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.raceAutoTxt {
    width: 100%;

    color: var(--gray8A8A8E-B3B3B3);
    text-align: right;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.raceAutoTxtUPD {
    width: 100%;

    color: var(--gray8A8A8E-B3B3B3);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.raceQty {
    display: flex;
    /* width: 3.5rem; */
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.raceQtyTxt {
    align-self: stretch;

    color: var(--gray8A8A8E-B3B3B3);
    text-align: right;
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.docs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;

    margin-top: 0.5rem;
}

.base {
    display: flex;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--grayf6f6f6-474747);
    color: #1c1c1c !important;
    text-decoration: none !important;
}

.hiddenLink {
    color: #1c1c1c !important;
    text-decoration: none !important;
    width: 100%;
    height: 100%;
}

.baseTxt {
    display: flex;
    justify-content: left;
    flex: 1 0 0;

    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2rem; /* 150% */
}

.downloadBtn {
    display: flex;
    width: 1rem;
    height: 1rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    border-radius: 0.5rem;
    border: 1px solid #e3e3e3;
    background: var(--white-gray);
}

.arrowImg {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.10713rem 0.28044rem 0.09144rem 0.26788rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.separator {
    margin-block-start: 0;
    margin-block-end: 0;
    height: 0.01px;
    align-self: stretch;
    border: 0.5px solid var(--graye3e3e3-a5a5a5);
    margin-top: 0.4em;

    margin-bottom: 0.4em;
    margin-left: -15px;
    margin-right: -15px;
}

.separatorDashhed {
    margin-block-start: 0;
    margin-block-end: 0;
    height: 0.01px;
    width: 100%;
    align-self: stretch;
    border: 0.5px dashed var(--graye3e3e3-a5a5a5);
    margin-top: 1rem;
    margin-bottom: 0.5em;
}

.textForUPD {
    display: flex;
    flex-direction: column;
}

.boldText {
    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 184.615% */
}

.midText {
    color: var(--Colors-progress-time_txt, #8a8a8e);
    text-align: center;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.bincihinin {
    display: flex;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
}
