:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --pizdec: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --gray8A8A8E-B3B3B3: #8A8A8E;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --pizdec: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --gray8A8A8E-B3B3B3: #B3B3B3;
}

.interval{
    display: flex;
    align-items: flex-start;
    position: relative;
    gap: var(--value);
    margin-top: 3px;
}

.intervalLine{
    display: flex;
    width: 265px;
    height: 23px;
    justify-content: space-between;
    align-items: flex-end;

    position: absolute;
    left: 67px;
    bottom: -8px;
}

hr.intervalSmallLine{
    width: 1px;
    height: 5px;
    flex-shrink: 0;
    background: var(--graycecece-d0d0d2);
}

hr.intervalSmallLine.selectedInterval{
    width: 1px;
    height: 23px;
    flex-shrink: 0;

    background: #f54941;
}

hr.intervalBigLine{
    width: 1px;
    height: 9px;
    flex-shrink: 0;
    background: #cecece;
}

hr.intervalBigLine.selectedInterval{
    width: 1px;
    height: 23px;
    flex-shrink: 0;

    background: #f54941;

    position: relative;
}

.interval span.intervalTitle{
    color: var(--gray8A8A8E-B3B3B3);
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.interval span.intervalValue{
    color: var(--txt-color);
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-small);
    font-style: normal;
    font-weight: 500;
    line-height: 18px;

    width: max-content;

    top: -5px;
    left: 7px;

    position: absolute;
}

.intervalPositionSelected{
    position: relative;
}

@media (max-width: 380px) {
    .intervalLine{
        display: flex;
        width: 230px;
        height: 23px;
        justify-content: space-between;
        align-items: flex-end;
    
        position: absolute;
        left: 67px;
        bottom: -8px;
    }

    .interval{
        display: flex;
        align-items: flex-start;
        position: relative;
        gap: calc(var(--value) - 12px);
        margin-top: 3px;
    }
  }

  @media (max-width: 347px) {
    .intervalLine{
        display: flex;
        width: 190px;
        height: 23px;
        justify-content: space-between;
        align-items: flex-end;
    
        position: absolute;
        left: 67px;
        bottom: -8px;
    }

    .interval{
        display: flex;
        align-items: flex-start;
        position: relative;
        gap: calc(var(--value) - 25px);
        margin-top: 3px;
    }
  }