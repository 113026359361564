:root[data-theme='light'] {
    --background-color-main: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
}

:root[data-theme='dark'] {
    --background-color-main: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    background: var(--background-color-main);
}

.date {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.accordeonPlaceholder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.accordeonPosition {
    display: flex;
    padding: 0rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.accordeon {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;

    background: var(--background-color-main);
}

.order {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.namePlaceholder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.name {
    display: flex;
    width: 19.4375rem;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}

.nomenclature {
    align-self: stretch;

    color: var(--txt-color);
    font-family: Inter;
    font-size: var(--txt-big);
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
}

.address {
    align-self: stretch;

    color: #8a8a8e;
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.separator {
    margin-block-start: 0;
    margin-block-end: 0;
    height: 0.01px;
    width: 100%;
    align-self: stretch;
    border: 0.5px dashed #e8e8e8;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.qty {
    display: flex;
    padding-top: 12px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 6.125rem;
    width: 100%;
}

.txt {
    display: flex;
    width: 100%;
    align-items: center;
}

.race {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: max-content;
    flex: 1 0 0;
}

.racesText {
    color: #8a8a8e;
    font-family: Inter;
    font-size: var(--txt-big);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.racesValue {
    color: var(--txt-color);
    font-family: Inter;
    font-size: var(--txt-big);
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.amount {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 0.25rem;
    flex: 1 0 0;
}

.amountText {
    color: #8a8a8e;
    font-family: Inter;
    font-size: var(--txt-big);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.amountValue {
    color: var(--txt-color);
    font-family: Inter;
    font-size: var(--txt-big);
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.arrow {
    display: flex;
    width: 1.125rem;
    height: 1.125rem;
    padding: 0.25rem 0rem 0.1925rem 0rem;
    justify-content: center;
    align-items: center;
}
