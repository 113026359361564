:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --gray8A8A8E-B3B3B3: #8A8A8E;
    --redA-rebB: #F54941;
  }
  
  :root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --gray8A8A8E-B3B3B3: #B3B3B3;
    --redA-rebB: #FF8B86;
  }

  .sendCodeOneMore{
    color: var(--redA-rebB);
  
    width: 85%;
  
    margin-top: 10px;
  
    margin-left: auto;
    margin-right: auto;
  
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }

  .sendCodeOneMoreDisabled{
    color: var(--gray8A8A8E-B3B3B3);
  
    width: 85%;
  
    margin-top: 10px;
  
    margin-left: auto;
    margin-right: auto;
  
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }