:root[data-theme='dark'] {
    --Colors-txt-primary: #ffffff;
    --stroke: #a5a5a5;
    --btn-secondry: #fff;
    --accept: #f54941;
}

:root[data-theme='light'] {
    --Colors-txt-primary: #202020;
    --stroke: #e3e3e3;
    --btn-secondry: #ffffff;
    --accept: #f54941;
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    padding-bottom: 1rem;
    gap: 1rem;
}

.lorem {
    padding: 1rem;
    color: var(--Colors-txt-primary, #202020);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.accept {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 0.5rem;

    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
}

.checkbox {
    outline: none;
    accent-color: var(--btn-secondry);
    width: 1rem;
    height: 1rem;
    appearance: none;
}

.checkbox:checked {
    width: 1rem;
    height: 1rem;
    appearance: none;
}

.checkbox::after {
    content: '';
    display: flex;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
}

.checkbox:checked::before {
    content: '\2713';
    position: absolute;
    display: inline-block;
    font-size: 24px;
    color: var(--accept);
}
