:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --pizdec: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
    --loaded-grey-black: rgba(203, 203, 209, 0.5);
    --gradientColor: #f0f0f0;
    --between-color: #c2c2c2;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --pizdec: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0);
    --loaded-grey-black: #474747;
    --gradientColor: #2c2c2c;
    --between-color: #6d6d6d;
}

div.fullBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;

    background: var(--background-color);
}

.logoPlaceholder {
    display: flex;
    padding: 0.75rem 0rem 1.5rem 0rem;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
}

.logo {
    width: 10.5rem;
    height: 2.5rem;
    flex-shrink: 0;
}

.numberInputPlaceholder {
    display: flex;
    padding: 1.5rem 0rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
}

.phoneNumberInput {
    display: flex;
    padding: 0rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
}

.numberInputTitle {
    color: var(--txt-color);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
}

.footerSendButton {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;

    bottom: 26px;
}

.sendButtonPlaceholder {
    display: flex;
    padding: 0rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-self: stretch;
}

.sendButton {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    height: 3rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: #a9a9a9;

    color: #fff;
    font-feature-settings: 'case' on;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 1, 5rem;
    border: none;
}

.sendButtonActive {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    height: 3rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: #f54941;

    color: #fff;
    font-feature-settings: 'case' on;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 1, 5rem;
    border: none;
}

.loginXd {
    overflow: hidden;
    color: var(--colors-txt-secondary, #202020);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

@media (max-width: 576px) {
    .registration-cssave form {
        padding: 50px 20px;
    }
}
