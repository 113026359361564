:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --pizdec: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --pizdec: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0);
}

.tapBar {
    display: flex;
    width: 100%;
    height: var(--height);
    filter: brightness(var(--brightness));
    padding-top: 8px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    bottom: 0px;
    margin-top: auto;
    background: var(--super-white-black);
    z-index: var(--isHidden);
    border-top: 0.5px solid var(--graye3e3e3-a5a5a5);
    /* transform: translateY(150%); */
    /* animation: animation3 0.45s forwards; */
}

/* @keyframes animation2{
    0% {opacity : 0;}
    100% {opacity: 1;}
}

@keyframes animation3 {
    0% {transform: translateY(150%);}
    100% {transform: translateY(0);}
  } */

.iphoneOffset {
    min-height: 2.125rem;
}

.navigationBar {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.navElement {
    display: flex;
    padding: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 1 0;
}

.navTitleActive {
    color: var(--txt-color);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

.navTitle {
    color: #b2b2b2;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

.createRequestButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}

.buttonCreateRequest {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 20px;
    background: #f54941;
}
