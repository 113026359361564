:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --pizdec: #fff;
    --ebanaya-xyunya: #f54941;
    --graye3e3e3-a5a5a5: #e3e3e3;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --pizdec: #323232;
    --ebanaya-xyunya: #fff;
    --graye3e3e3-a5a5a5: #a5a5a5;
}

.footer {
    display: flex;
    flex-direction: column;
    padding: 12px 12px 12px 12px;
    align-items: center;
    margin-top: auto;
    position: relative;
    gap: 0.7rem;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    flex: 1 0 0;
}

.operatorButton {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--colors-accents-brand-primary, #f54941);

    color: var(--color-base-white, #fff);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */

    border: none;
}

.operatorButtonWhite {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    border-radius: 0.5rem;
    border: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--white-gray);

    color: #fff;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
}

/* стили из удалённого компонента*/

:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --aahahahahahah: #fff;
    --xdddddd: #f54941;
    --graye3e3e3-a5a5a5: #e3e3e3;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --aahahahahahah: #323232;
    --xdddddd: #fff;
    --graye3e3e3-a5a5a5: #a5a5a5;
}

.footer123 {
    display: flex;
    width: 93%;
    padding: 12px 12px 12px 12px;
    flex-direction: column;
    align-items: center;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 0.5rem;
    flex: 1 0 0;

    width: 88%;
    padding: 5px 5px 5px 5px;
}

.changeButton {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--xdddddd);

    color: var(--white-gray);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */

    border: none;
    width: 100%;
}

.changeButtonDisabled {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--colors-btn-main-disabled, #d0d0d2);
    color: #fff;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */

    border: none;
}

.changeButtonWhite {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--white-gray);

    color: var(--xdddddd);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */

    border: none;
}

.deleteRequest {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid #e3e3e3;
    background: #f2f2f7;
}
