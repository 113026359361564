:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC;
    --blue-notgayborhood: #007AFF;
    --orangeW-orangeB: #F59741;
    --greenA-greenB: #42B069;
    --redA-rebB: #F54941;
    --gray8A8A8E-B3B3B3: #8A8A8E;
    --B4B4C5-8A8A8E: #B4B4C5;
    --switcher-color: rgba(116, 116, 128, 0.08);
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #202020;
    --grey-black: rgba(255, 255, 255, 0.00);
    --blue-notgayborhood: #78B9FF;
    --orangeW-orangeB: #FFAC60;
    --greenA-greenB: #7FEA9A;
    --redA-rebB: #FF8B86;
    --gray8A8A8E-B3B3B3: #B3B3B3;
    --B4B4C5-8A8A8E: #8A8A8E;
    --switcher-color: #323232;
}

.content{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.erkon_sum{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.erkoins{
    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
    text-transform: uppercase;
}

.amount{
    display: flex;
    align-items: center;
    gap: 6px;
    align-self: stretch;
}

.erkoinsTxt{
    color: var(--txt-color);
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 31.2px */ 
}

.blocks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.add_info{
    display: flex;
    padding: 14px 20px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--switcher-color);
}

.tabTxt{
    flex: 1 0 0;

    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
}

.choose_card{
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--kal-kalich2);
}

.contentCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.cardTxt{
    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
    text-transform: uppercase;
}

.cards{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 10px;
    align-self: stretch;
    flex-wrap: wrap; 
}

.cardSelected{
    display: flex;
    padding: 2.695px;
    flex-direction: column;
    align-items: flex-start;
    gap: 9.239px;

    border-radius: 6.467px;
    border: 1.386px solid var(--colors-accents-red-btn-mini, #F54941);
}

.card{
    display: flex;
    padding: 2.695px;
    flex-direction: column;
    align-items: flex-start;
    gap: 9.239px;

    border-radius: 6.467px;
    border: 1.386px solid var(--colors-stroke, #E3E3E3);
}

.pic{
    display: flex;
    padding: 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.sendBtnActive{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border: none;

    color: var(--color-base-white, #FFF);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */

    margin-left: auto;
    margin-right: auto;
    width: 100%;

    border-radius: 8px;
    background: var(--colors-accents-brand-primary, #F54941);
}

.sendBtn{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border: none;

    margin-left: auto;
    margin-right: auto;
    width: 100%;

    color: var(--colors-txt-btn-main-disabled, rgba(203, 203, 209, 0.50));
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */

    border-radius: 8px;
    background: var(--colors-btn-main-disabled, #8A8A8E);
}