:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
    --blue-notgayborhood: #007aff;
    --orangeW-orangeB: #f59741;
    --greenA-greenB: #42b069;
    --redA-rebB: #f54941;
    --gray8A8A8E-B3B3B3: #8a8a8e;
    --B4B4C5-8A8A8E: #b4b4c5;
    --switcher-color: rgba(116, 116, 128, 0.08);
    --green42B069-34c759: #42b069;
    --B3B3B3-A5A5A5: #b3b3b3;
    --G8A8A8E-D0D0D2: #8a8a8e;
    --backgorundXdd: #fff;
    --fff-8a8a8e: #fff;
    --f2f2f7-474747: #f2f2f7;
    --xdddd-xddddddd: #fff;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #202020;
    --grey-black: rgba(255, 255, 255, 0);
    --blue-notgayborhood: #78b9ff;
    --orangeW-orangeB: #ffac60;
    --greenA-greenB: #7fea9a;
    --redA-rebB: #ff8b86;
    --gray8A8A8E-B3B3B3: #b3b3b3;
    --B4B4C5-8A8A8E: #8a8a8e;
    --switcher-color: #323232;
    --green42B069-34c759: #34c759;
    --B3B3B3-A5A5A5: #a5a5a5;
    --G8A8A8E-D0D0D2: #d0d0d2;
    --backgorundXdd: #323232;
    --fff-8a8a8e: #8a8a8e;
    --f2f2f7-474747: #474747;
    --xdddd-xddddddd: #323232;
}

.activeCards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.truckStatusGroup {
    display: flex;
    padding-bottom: 0px;
    align-items: flex-start;
    align-self: stretch;
    padding-bottom: 12px;

    border-bottom: 1px solid var(--graye3e3e3-a5a5a5);
}

.info {
    display: flex;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
}

.name {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
}

.right {
    display: flex;
    align-items: flex-start;
    gap: 30px;
}

.nameTxt {
    color: var(--txt-color);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.summmary {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

.totalCapacity {
    color: var(--gray8A8A8E-B3B3B3);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.totalAbs {
    color: var(--gray8A8A8E-B3B3B3);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.progress_cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.progress_card {
    display: flex;
    padding: 20px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--xdddd-xddddddd);
}

.progressInfo {
    display: flex;
    height: 82px;
    width: 98%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
}

.progressInfoPlaceholder {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.progress_graphics {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    align-self: stretch;
}

.destinationPlaceholder {
    position: absolute;

    top: -50px;
    right: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.progress_graphicsPlaceholder {
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    position: relative;
}

.progress_lines {
    width: 98%;
    height: 2px;

    position: absolute;
    margin-left: 5px;
    bottom: 18px;
}

.grey_line {
    width: 95%;
    height: 2px;

    position: absolute;

    border-radius: 20px;
    background: var(--B3B3B3-A5A5A5);
}

.green_line {
    width: 50%;
    height: 2px;

    position: absolute;

    border-radius: 20px;
    background: var(--green42B069-34c759);
}

.green_lineFullWidth {
    width: 95%;
    height: 2px;

    position: absolute;

    border-radius: 20px;
    background: var(--green42B069-34c759);
}

.process_element {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
}

.process_elementPlaceholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
}

.timeStatus {
    color: var(--G8A8A8E-D0D0D2);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
}

.circle_start {
    display: flex;
    z-index: 1;
    width: 32px;
    height: 32px;
    padding: 5px 3px 5px 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 30px;
    background: var(--green42B069-34c759);
}

.truck {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;

    position: absolute;
    left: 19%;
    top: -20px;
}

.truckRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;

    position: absolute;
    right: 17%;
    top: -20px;
}

.time_current {
    display: flex;
    padding: 2px 6px 2px 8px;
    align-items: flex-start;
    gap: 10px;

    border-radius: 34px;
    border: 1px solid var(--colors-stroke-dark, #a5a5a5);
}

.time_currentProstoy {
    display: flex;
    padding: 2px 6px 2px 8px;
    align-items: flex-start;
    gap: 10px;

    border-radius: 34px;
    border: 1px solid var(--redA-rebB);
}

.timeTruck {
    color: var(--txt-color);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.timeTruckProsoy {
    color: var(--redA-rebB);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.process_elementCenter {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
}

.process_elementCenterPlaceholder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.process_elementCenterPlace {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    align-self: stretch;
}

.circeEmpty {
    display: flex;
    width: 33px;
    height: 29px;
    bottom: 0px;
    padding: 5px 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    position: absolute;

    z-index: 1;

    border-radius: 40px;
    border: 2px solid var(--B3B3B3-A5A5A5);
    background: var(--backgorundXdd);
}

.process_elementRight {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
}

.process_elementRightPlaceholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
}

.process_elementRightPlace {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    align-self: stretch;
}

.statuses {
    display: flex;
    padding: 0px 10px;
    align-items: flex-start;
    align-self: stretch;

    margin-top: -12px;
}

.statusTxt {
    flex: 1 0 0;

    color: var(--txt-color);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
}

.time_on_road {
    display: flex;
    width: 48%;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;

    position: absolute;
    bottom: 30px;
    left: 11px;
}

.time_on_roadRight {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;

    position: absolute;
    bottom: 30px;
    right: 9px;
}

.time_on_roadPlaceholder {
    display: flex;
    padding: 2px 6px 2px 8px;
    align-items: flex-start;
    gap: 10px;

    border-radius: 34px;
    border: 1px solid var(--fff-8a8a8e);
    background: var(--f2f2f7-474747);
}

.time_on_roadTxt {
    color: var(--G8A8A8E-D0D0D2);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.bottom_info {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.truck_info {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
}

.truck_num {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.volume {
    display: flex;
    width: 56px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.amount {
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.naming {
    color: var(--gray8A8A8E-B3B3B3);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

.carButtons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.showOnTheMap {
    display: flex;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    border-radius: 0.5rem;
    border: 1px solid var(--graye3e3e3-a5a5a5);
}

.callButton {
    display: flex;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    border-radius: 0.5rem;
    border: 1px solid var(--graye3e3e3-a5a5a5);
}

.prostoyPlaceholder {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.prostoyTxt {
    flex: 1 0 0;

    color: var(--redA-rebB);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
}
