:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC; 
    --switcher-color: rgba(116, 116, 128, 0.08);
    --gray8A8A8E-B3B3B3: #8A8A8E;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0.00);
    --switcher-color: #323232;
    --gray8A8A8E-B3B3B3: #B3B3B3;
}

.titleAndStatus{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
}

.requestChangeStatus{
    color: var(--gray8A8A8E-B3B3B3);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.statusTitle{
    display: flex;
    position: relative;
    align-items: center;
    gap: 4px;
}

.backButton{
    position: absolute;
}