@mixin box-shadow($shadow...)
    -webkit-box-shadow: $shadow
    -moz-box-shadow: $shadow
    box-shadow: $shadow

.container
    display: flex
    width: 100%
    height: 2.5rem
    padding: 0.25rem
    align-items: center
    gap: 0.5rem
    border-radius: 0.75rem
    background: var(--Color_new_sigment, #E8E8EE)

    color: var(--Text-base, #252627)
    font-family: Inter
    text-align: center
    font-size: 1.125rem
    font-weight: 400
    line-height: 1.375rem

.choice
    display: flex
    width: 100%
    gap: 0.5rem
    height: 100%

    color: var(--Text-base, #252627)
    font-size: 1rem
    font-weight: 400
    line-height: 1.25rem
.chosen,
.notChosen,
.disabled
    display: flex
    height: 100%
    width: 100%
    align-items: center
    justify-content: center
    flex: 1 0
    border-radius: 0.5rem

.chosen
    background: var(--Button-shadow-normal, #FFF)

    @include  box-shadow( 0px 3px 8px -1px var(--Other-shadow, rgba(68, 83, 113, 0.05)), 0px 1px 4px 0px var(--Other-shadow, rgba(68, 83, 113, 0.05)), 0px 0px 2px 0px var(--Other-shadow, rgba(68, 83, 113, 0.05)))

.disabled
    color: var(--Text-non-active, #8a8b8c)

.separator
    padding: 0.5rem 0
