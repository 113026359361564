.fiveContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
}

.headTxt {
    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
    text-transform: uppercase;
}

.headerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.pieChart {
    display: flex;
    padding: 1.25rem 1.25rem 1.25rem 0.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--Colors-plashka_card_info, #fff);
}

.blockContainer {
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: #fff;
}

.txt {
    display: flex;
    height: 3rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.125rem;
}

.totalSum {
    align-self: stretch;
    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.headerTxt {
    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 184.615% */
}

.lowerTxt {
    color: var(--Colors-progress-time_txt, #8a8a8e);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.66031rem; /* 88.038% */
}

.amount {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    align-self: stretch;
}

.amountTxt {
    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 1.95rem */
}

.firstBlockContainer {
    display: flex;
    align-items: center;
    gap: 5%;
    align-self: stretch;
}

.buttonContainer {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
}

.allButton {
    color: var(--Colors-Accents-blue-accent-links, #007aff);
    font-family: 'Inter';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 133.333% */
}

.downloadButton {
    display: flex;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--redA-rebB);
    color: #fff;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    border: none;
    height: 24px;
}
