.accordeon{
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 0.5rem;
    border-bottom: 1px solid var(--colors-stroke, #E3E3E3);
    background: var(--white-gray);
}

.accordeonHeaderPlaceholder{
    display: flex;
    align-items: center ;
    align-self: stretch;
}

.accordeonHeader{
    flex: 1 0 0;

    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
}

.txt{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
}

.policyHeader{
    align-self: stretch;
    flex: 1 0 0;

    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.policyContent{
    width: 95%;
    
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.accordeon_txt{
    align-self: stretch;

    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}