:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
    --blue-notgayborhood: #007aff;
    --orangeW-orangeB: #f59741;
    --greenA-greenB: #42b069;
    --redA-rebB: #f54941;
    --gray8A8A8E-B3B3B3: #8a8a8e;
    --B4B4C5-8A8A8E: #b4b4c5;
    --switcher-color: rgba(116, 116, 128, 0.08);
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #202020;
    --grey-black: rgba(255, 255, 255, 0);
    --blue-notgayborhood: #78b9ff;
    --orangeW-orangeB: #ffac60;
    --greenA-greenB: #7fea9a;
    --redA-rebB: #ff8b86;
    --gray8A8A8E-B3B3B3: #b3b3b3;
    --B4B4C5-8A8A8E: #8a8a8e;
    --switcher-color: #323232;
}

body {
    background: var(--background-color);
    align-self: stretch;
    min-height: 100%;
    height: 100%;
}

.wrapper {
    width: 100%;
    flex-direction: column;
    display: flex;
    min-height: 100%;
}

.main {
    flex: 1 1 auto;
}

.headerRequests {
    position: sticky;
    display: flex;
    background: var(--super-white-black);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    height: 73px;

    z-index: 1;
    width: 100%;
    position: fixed;

    /* border-bottom: 1px solid var(--graye3e3e3-a5a5a5); */
    /* border-bottom: 1px solid var(--valueBorder); */
}

.datePicker {
    display: flex;
    padding: 0px 13px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.datePicker span.weekDay {
    color: var(--gray8A8A8E-B3B3B3);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;

    align-self: stretch;
}

.footerTxt {
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.footerTxtBold {
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
}

.footerSendButtonAddress {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding-bottom: 20px;
    padding-top: 5px;
    border-top: 0.5px solid var(--graye3e3e3-a5a5a5);
    background-color: var(--super-white-black);
    width: 100%;
    bottom: 0px;
}

.datePicker span.weekNumberDay {
    color: var(--txt-color);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
}

.datePicker.button {
    display: flex;
    width: 2.8rem;
    height: 64px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    background-color: var(--super-white-black);
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s linear;
}

.datePicker.button.currentDate {
    background-color: var(--choosen-color);
    transition: background-color 0.3s linear;
}

.requestsPlaceholder {
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.requestPosition {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.requestTicket {
    display: flex;
    padding: 16px 16px 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    /* width: 100%; */

    border-radius: 8px;
    border-bottom: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--kal-kalich2);
}

.requestHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.nomenclatureAndStatusRequest {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-self: stretch;
}

.nomenclatureAndStatusRequest div.status {
    display: flex;
    align-items: center;
    gap: 4px;
}

.nomenclatureAndStatusRequest div.status span.statusText {
    color: var(--gray8A8A8E-B3B3B3);
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

span.requestNomenclature {
    color: var(--txt-color);
    width: 50%;
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-big);
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

span.requestNomenclatureLong {
    color: var(--txt-color);
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-big);
    font-style: normal;
    font-weight: 700;
    height: 26px;
    line-height: 24px;
}

.requestAddress {
    color: var(--gray8A8A8E-B3B3B3);
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    width: 14.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.requestAddressFullSize {
    color: var(--gray8A8A8E-B3B3B3);
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

hr.requestLine {
    margin-block-start: 0;
    margin-block-end: 0;
    height: 0.01px;
    width: 100%;
    align-self: stretch;
    border: 0.5px dashed var(--graye3e3e3-a5a5a5);
    margin-top: 0.7em;
    margin-bottom: 0.5em;
}

.timeAndCapacityInfo {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
}

.infoTimeAndCapacityBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}

.infoTimeAndCapacityBlock span.capacityTitle {
    color: var(--gray8A8A8E-B3B3B3);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.infoTimeAndCapacityBlock span.capacityTitleRed {
    color: var(--redA-rebB);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.capacityTitleRed {
    color: var(--redA-rebB);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: 18px;
}

.changedValueFrame {
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
}

.infoTimeAndCapacityBlock span.capacityValue {
    color: var(--txt-color);
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.card_statusActive {
    display: flex;
    padding: 0.1875rem 0.625rem;
    justify-content: flex-end;
    align-items: center;
    gap: 0.625rem;

    border-radius: 1.25rem;
    border: 1px solid var(--greenA-greenB);
}

.card_statusPause {
    display: flex;
    padding: 0.1875rem 0.625rem;
    justify-content: flex-end;
    align-items: center;
    gap: 0.625rem;

    border-radius: 1.25rem;
    border: 1px solid var(--redA-rebB);
}

.card_statusWaiting {
    display: flex;
    padding: 0.1875rem 0.625rem;
    justify-content: flex-end;
    align-items: center;
    gap: 0.625rem;

    border-radius: 1.25rem;
    border: 1px solid var(--orangeW-orangeB);
}

.card_statusPlanned {
    display: flex;
    padding: 0.1875rem 0.625rem;
    justify-content: flex-end;
    align-items: center;
    gap: 0.625rem;

    border-radius: 1.25rem;
    border: 1px solid var(--blue-notgayborhood);
}

.card_statusComplete {
    display: flex;
    padding: 0.1875rem 0.625rem;
    justify-content: flex-end;
    align-items: center;
    gap: 0.625rem;

    border-radius: 1.25rem;
    border: 1px solid var(--color-gray-gray-150, #ececec);
    background: var(--grey-black);
}

.card_statusActiveTxt {
    color: var(--greenA-greenB);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.card_statusPauseTxt {
    color: var(--redA-rebB);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.card_statusWaitingTxt {
    color: var(--orangeW-orangeB);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.card_statusPlannedTxt {
    color: var(--blue-notgayborhood);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.card_statusCompleteTxt {
    color: var(--txt-color);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.iconPlaceholderXddd {
    display: flex;
    height: 35.875rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.iconTxtxDDD {
    color: var(--B4B4C5-8A8A8E);
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: var(--txt-big);
    font-style: normal;
    font-weight: 400;
    width: 65vw;
    line-height: 1.5rem; /* 150% */
}

.uniqueHeaderXd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 0.625rem;
    align-self: stretch;
}

.longStatusPlan {
    display: flex;
    padding: 0.1875rem 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;

    margin-bottom: 0.6rem;

    border-radius: 1.25rem;
    border: 1px solid var(--blue-notgayborhood);
}

.longStatusTxtPlan {
    color: var(--blue-notgayborhood);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 150% */
}

.longStatusWaiting {
    display: flex;
    padding: 0.1875rem 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;

    margin-bottom: 0.6rem;

    border-radius: 1.25rem;
    border: 1px solid var(--orangeW-orangeB);
}

.longStatusTxtWaiting {
    color: var(--orangeW-orangeB);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 150% */
}

.footerBtns {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
