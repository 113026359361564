.modal{
    position: fixed;
    width: 100%;
    bottom: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 99999;
}

.modal__content{
    position: absolute;
    display: flex;
    /* height: 40%; */
    padding: 1rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    flex-shrink: 0;
    border-radius: 16px;
    width: 70%;
    /* bottom: 0; */
    background-color: var(--kal-kalich2);
}

.modal__header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    justify-content: center;
}

.modal__body{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;

    color: #8a8a8e;
    font-family: 'Inter', sans-serif;
    text-align: center;
    font-feature-settings: "case" on;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.modal__title{
    color: var(--txt-color);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

.modal__footer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    flex-direction: column;
}

.modal__button{
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    border-radius: 0.5rem;

    background-color: #1c1c1c00;
    color: var(--blue-notgayborhood);
    font-feature-settings: "case" on;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    border-top: 1px solid var(--graye3e3e3-a5a5a5);
    border-bottom: none;
    border-left: none;
    border-right: none;
    text-decoration: none !important;
}