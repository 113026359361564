:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC;
    --blue-notgayborhood: #007AFF;
    --orangeW-orangeB: #F59741;
    --greenA-greenB: #42B069;
    --redA-rebB: #F54941;
    --gray8A8A8E-B3B3B3: #8A8A8E;
    --B4B4C5-8A8A8E: #B4B4C5;
    --switcher-color: rgba(116, 116, 128, 0.08);
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #202020;
    --grey-black: rgba(255, 255, 255, 0.00);
    --blue-notgayborhood: #78B9FF;
    --orangeW-orangeB: #FFAC60;
    --greenA-greenB: #7FEA9A;
    --redA-rebB: #FF8B86;
    --gray8A8A8E-B3B3B3: #B3B3B3;
    --B4B4C5-8A8A8E: #8A8A8E;
    --switcher-color: #323232;
}

.CarSelectorPlaceholder{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.togglePlaceholder{
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    /* padding-bottom: 8px; */
}

.toggleName{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;

    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

input.switcher[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  label.labelForSwitcher {
    cursor: pointer;
    text-indent: -9999px;
    width: 2.5rem;
    height: 1.375rem;
    flex-shrink: 0;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }
  
  label.labelForSwitcher:after {
    content: '';
    position: absolute;
    top: 2.1px;
    left: 3px;
    width: 16px;
    height: 17px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }
  
  input.switcher:checked + label {
    background: #34C759;
  }
  
  input.switcher:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }
  
  label.switcher:active:after {
    width: 15px;
  }