:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10)
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
}

.content{
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.mainTxt{
    align-self: stretch;

    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.accordeon{
    display: flex;
    padding: 1rem;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;

    border-radius: 0.5rem;
    border-bottom: 1px solid var(--colors-stroke, #E3E3E3);
    background: var(--white-gray);
}

.accordeonHeader{
    flex: 1 0 0;

    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
}

.arrowPrikol{
    display: flex;
    width: 1.08331rem;
    height: 1.625rem;
    padding: 0rem 0rem 0.4425rem 0rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}