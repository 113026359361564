:root[data-theme='light']{
    --background-color: #fff;
    --fff-202020: #fff;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC;
    --blue-notgayborhood: #007AFF;
    --orangeW-orangeB: #F59741;
    --greenA-greenB: #42B069;
    --redA-rebB: #F54941;
    --gray8A8A8E-B3B3B3: #8A8A8E;
    --switcher-color: rgba(116, 116, 128, 0.08);
}

:root[data-theme='dark']{
    --background-color: #202020;
    --fff-202020: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0.00);
    --blue-notgayborhood: #78B9FF;
    --orangeW-orangeB: #FFAC60;
    --greenA-greenB: #7FEA9A;
    --redA-rebB: #FF8B86;
    --gray8A8A8E-B3B3B3: #B3B3B3;
    --switcher-color: #323232;
}

.header{
    background-color: var(--super-white-black);
    position: fixed;

    width: 100%;
    height: var(--heightValue);
    border-bottom: 1px solid var(--graye3e3e3-a5a5a5);

    z-index: 9999;
}