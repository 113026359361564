:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --graye3e3e3-a5a5a5: #a5a5a5;

    --choosen-color: rgba(245, 73, 65, 0.1);
    --pizdec: #fff;
    --ebanaya-xyunya: #f54941;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --pizdec: #323232;
    --ebanaya-xyunya: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;

}

.name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.125rem;
    align-self: stretch;
}

.avatar {
    width: 3.125rem;
    height: 3.125rem;
    position: relative;
}

.bigCharName {
    color: var(--ebanaya-xyunya);
    display: flex;
    justify-content: center;
    font-family: Inter;
    font-size: 1.91956rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    position: absolute;
    margin-right: auto;
    margin-left: auto;

    top: 5px;
    bottom: 0;
    left: 0;
    right: 0;
}

.fullName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
}

.fullNameTxt {
    align-self: stretch;

    color: var(--txt-color);
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.phoneTxt {
    align-self: stretch;

    color: var(--txt-color);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.notification_icon {
    width: 2.5rem;
    height: 2.5rem;
}

.iconPlaceholder {
    display: inline-flex;
    padding: 0.625rem;
    align-items: flex-start;
    gap: 0.625rem;
    right: 5px;

    border-radius: 1.25rem;
    border: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--white-gray);
}

.circleAttention {
    position: absolute;
    right: 5px;
}

.picture {
    fill: var(--txt-color);
}

.avatarIcon {
    fill: var(--white-gray);
}
