:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --second-background: #fff;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
    --blue-notgayborhood: #007aff;
    --orangeW-orangeB: #f59741;
    --greenA-greenB: #42b069;
    --redA-rebB: #f54941;
    --gray8A8A8E-B3B3B3: #8a8a8e;
    --B4B4C5-8A8A8E: #b4b4c5;
    --switcher-color: rgba(116, 116, 128, 0.08);
    --skip-button: #2d2b2e;
    --skip-buttonWhite: #2d2b2e;
    --btnRed-White: #f54941;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --second-background: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #202020;
    --grey-black: rgba(255, 255, 255, 0);
    --blue-notgayborhood: #78b9ff;
    --orangeW-orangeB: #ffac60;
    --greenA-greenB: #7fea9a;
    --redA-rebB: #ff8b86;
    --gray8A8A8E-B3B3B3: #b3b3b3;
    --B4B4C5-8A8A8E: #8a8a8e;
    --switcher-color: #323232;
    --skip-button: #b3b3b3;
    --skip-buttonWhite: #fff;
    --btnRed-White: #fff;
}

.header {
    display: flex;
    /* width: 100%; */
    padding: 14px 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;

    background: var(--second-background);
}

.skipTxt {
    color: var(--skip-button);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.4px;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 0px 16px;

    padding-top: 10%;
}

.infoLargeImgBox {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;

    top: 500px;

    padding: 0px 16px;
}

.txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
}

.swiper {
    display: flex;
    height: 93vh;
    align-items: center;

    background: var(--second-background);
}

.txtPlaceholder {
    display: flex;
    width: 100%;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.txtUpper {
    align-self: stretch;

    color: var(--skip-buttonWhite);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.4px;
    margin-bottom: 8px;
}

.txtLower {
    align-self: stretch;

    color: var(--skip-button);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.4px;
}

.checkBtnPlaceholder {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;

    gap: 8px;
    flex: 1 0 0;

    border-radius: 8px;
    background: #f54941;
}

.checkBtn {
    border-radius: 8px;
    background: #f54941;
    color: var(--Color-Base-white, #fff);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */

    border: none;
}

.backButtonPlaceholder {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;

    gap: 8px;
    border-radius: 8px;
    background: var(--white-gray);
    border: 1px solid var(--graye3e3e3-a5a5a5);
}

.backButton {
    border-radius: 8px;
    background: var(--white-gray);
    color: var(--btnRed-White);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */

    border: none;
}

.footer {
    display: flex;

    position: absolute;

    width: 92%;

    bottom: 25px;
    padding: 12px 16px;
    align-items: center;
    gap: 12px;
}
