:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
    --blue-notgayborhood: #007aff;
    --orangeW-orangeB: #f59741;
    --greenA-greenB: #42b069;
    --redA-rebB: #f54941;
    --gray8A8A8E-B3B3B3: #8a8a8e;
    --B4B4C5-8A8A8E: #b4b4c5;
    --switcher-color: rgba(116, 116, 128, 0.08);
    --green42B069-34c759: #42b069;
    --B3B3B3-A5A5A5: #b3b3b3;
    --G8A8A8E-D0D0D2: #8a8a8e;
    --backgorundXdd: #fff;
    --fff-8a8a8e: #fff;
    --f2f2f7-474747: #f2f2f7;
    --xdddd-xddddddd: #fff;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #202020;
    --grey-black: rgba(255, 255, 255, 0);
    --blue-notgayborhood: #78b9ff;
    --orangeW-orangeB: #ffac60;
    --greenA-greenB: #7fea9a;
    --redA-rebB: #ff8b86;
    --gray8A8A8E-B3B3B3: #b3b3b3;
    --B4B4C5-8A8A8E: #8a8a8e;
    --switcher-color: #323232;
    --green42B069-34c759: #34c759;
    --B3B3B3-A5A5A5: #a5a5a5;
    --G8A8A8E-D0D0D2: #d0d0d2;
    --backgorundXdd: #323232;
    --fff-8a8a8e: #8a8a8e;
    --f2f2f7-474747: #474747;
    --xdddd-xddddddd: #323232;
}

.carLoading {
    width: 100%;
    height: 11.75rem;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(
        90deg,
        var(--loaded-grey-black),
        var(--between-color),
        var(--gradientColor)
    );
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.graphicProgress {
    width: 100%;
    box-sizing: border-box;
    height: 5.125rem;
    padding: 16px;
    margin-top: 0.5rem;
    border-radius: 8px;

    background: linear-gradient(
        270deg,
        var(--loaded-grey-black),
        var(--between-color),
        var(--gradientColor)
    );
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.activeCars {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.secondRow {
    display: flex;
    justify-content: space-between;
}

.carInfo {
    width: 30%;
    box-sizing: border-box;
    height: 2.625rem;
    border-radius: 8px;

    background: linear-gradient(
        270deg,
        var(--loaded-grey-black),
        var(--between-color),
        var(--gradientColor)
    );
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.carButtons {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.carButton {
    width: 50px;
    box-sizing: border-box;
    height: 50px;
    border-radius: 8px;

    background: linear-gradient(
        270deg,
        var(--loaded-grey-black),
        var(--between-color),
        var(--gradientColor)
    );
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sideHeader {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
}

.miniLoader {
    width: 4rem;
    height: 1rem;
    background: linear-gradient(
        270deg,
        var(--loaded-grey-black),
        var(--between-color),
        var(--gradientColor)
    );
    border-radius: 8px;
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

@keyframes AnimationGradient {
    0% {
        background-position: 0% 51%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 51%;
    }
}
