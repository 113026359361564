:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --gray8A8A8E-B3B3B3: #8A8A8E;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --gray8A8A8E-B3B3B3: #B3B3B3;
}

.content{
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.textField{
    display: flex;
    padding: 0.625rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--white-gray);
}

.nameLabel{
    display: -webkit-box;
    width: 19.4375rem;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    color: var(--gray8A8A8E-B3B3B3);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 133.333% */
}

.nameValue{
    display: -webkit-box;
    width: 19.4375rem;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    color: var(--txt-color);
    background: var(--colors-plashka-parametr, rgba(116, 116, 128, 0));
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.submitBtn{
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--colors-accents-brand-primary, #F54941);

    border: none;

    color: var(--color-base-white, #FFF);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
}

.submitBtnDisabled{
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    border-radius: 0.5rem;
    border: 1px solid var(--colors-stroke, #E3E3E3);
    background: var(--purple-btn-tertiary-active, #ECECF5);
    border: none;

    color: var(--color-base-white, #FFF);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
}