:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
    --blue-notgayborhood: #007aff;
    --orangeW-orangeB: #f59741;
    --greenA-greenB: #42b069;
    --redA-rebB: #f54941;
    --gray8A8A8E-B3B3B3: #8a8a8e;
    --switcher-color: rgba(116, 116, 128, 0.08);
    --d0d0d2-8a8a8e: #d0d0d2;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0);
    --blue-notgayborhood: #78b9ff;
    --orangeW-orangeB: #ffac60;
    --greenA-greenB: #7fea9a;
    --redA-rebB: #ff8b86;
    --gray8A8A8E-B3B3B3: #b3b3b3;
    --switcher-color: #323232;
    --d0d0d2-8a8a8e: #8a8a8e;
}

.piechart_info {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--kal-kalich2);

    /* width: 40%; */
}

.piechart_infoPlaceholder {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.pieChartTxt {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
}

.left_to_save {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
}

.txtPlaceholder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
}

.txt {
    align-self: stretch;

    color: var(--txt-color);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
}

.amount {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch;
}

.amountValue {
    color: var(--txt-color);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 31.2px */
}

.buttonsDisabled {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--d0d0d2-8a8a8e);

    color: #fff;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */

    border: none;
    height: 48px;
}

.buttonsActive {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--colors-accents-brand-primary, #f54941);
    color: #fff;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */

    border: none;
}

.pieChartInsideTxt {
    color: var(--txt-color);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
}

.collectedTxt {
    color: var(--txt-color);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
}

.ballsOverall {
    color: var(--txt-color);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
}

.alarmContainer {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
}

.alarm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
}

.boldCertificateTxt {
    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 0.975rem */

    align-self: stretch;
}

.regularCertificateTxt {
    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: var(--txt-small);
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 0.825rem */

    align-self: stretch;
}

@media (max-width: 336px) {
    .collectedTxt {
        color: var(--txt-color);
        text-align: center;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 23.4px */
    }

    .ballsOverall {
        color: var(--txt-color);
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 23.4px */
    }
}
