:root[data-theme='light']{
    --background-color-main: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --gray8A8A8E-B3B3B3: #8A8A8E;
    --searchColor: rgba(116, 116, 128, 0.158);
    --searchColorTxt: rgba(116, 116, 128, 0);
}

:root[data-theme='dark']{
    --background-color-main: #1C1C1C;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --gray8A8A8E-B3B3B3: #B3B3B3;
    --searchColor: #323232;
    --searchColorTxt: #323232;
}

.modalBody{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;

    background: var(--background-color-main);
}

.contentContainer{
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.contentFields{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.contentTicket{
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    border-bottom: 1px solid #d0d0d0;
    background: var(--white-gray);
}

  .contentTicketDoubleSpan{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
  }

  .nameContentTxt{
    align-self: stretch;

    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }

  .phoneTxt{
    align-self: stretch;

    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }

.contentTicket span.contentTxt{
    flex: 1 0 0;

    color: var(--txt-color);
    font-feature-settings: "case" on;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.contentTicket span.phoneTxt{
    align-self: stretch;

    color: var(--gray8A8A8E-B3B3B3);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 171.429% */
  }

.boxContaienr{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}

.searchField{
  display: flex;
  padding: 8px 16px 16px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  width: 89%;

  top: 55px;
  position: fixed;
}

.searchFieldPlaceholder{
  display: flex;
  padding: 7px 8px;
  align-items: center;
  flex: 1 0 0;

  border-radius: 10px;
  background: var(--searchColor);
}

.searchIcon{
  display: flex;
  height: 22px;
  padding: 2.857px 7px 2.987px 2px;
  align-items: center;
}

.searchTxt{
  height: 22px;
  flex: 1 0 0;

  overflow: hidden;
  color: var(--txt-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.4px;

  background: var(--searchColorTxt);
}