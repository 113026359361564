:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --txt-second: #8a8a8e;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --txt-second: #b3b3b3;
}

.pushList {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 1rem;
    color: var(--txt-color);
    font-family: Inter;
    background-color: var(--background-color);
}

.content {
    width: 100%;
    box-sizing: border-box;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.notification {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    border-radius: 0.5rem;
    border-bottom: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--white-gray);
    line-height: 23px;
}

.notificationBody {
    white-space: 'pre-line';
    font-size: 1rem;
    font-weight: 400;
}

.notificationMainTxt {
    font-size: 1rem;
    font-weight: 600;
}

.notificationDate {
    color: var(--txt-second);
    font-size: 0.875rem;
    font-weight: 400;
}

.day {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
