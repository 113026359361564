:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --gray8A8A8E-B3B3B3: #8A8A8E;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --gray8A8A8E-B3B3B3: #B3B3B3;
}

.inputRequestMenuDateAndTime{
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    /* padding: 16px; */
}
.chooseFieldDate{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 0.5rem; */
}

.chooseFieldTime{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}

.textField{
    display: flex;
    height: 3.75rem;
    padding: 0rem 1rem;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;

    border-radius: 0.5rem;
    border-bottom: 1px solid #D0D0D0;
    background: var(--white-gray);
}

.icon{
    display: flex;
    width: 1.5rem;
    height: 1.25rem;
    padding: 0.125rem 0.18631rem 0.08356rem 0.1875rem;
    justify-content: center;
    align-items: center;
}

.fieldValue{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;

    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: "case" on;
    text-overflow: ellipsis;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background: transparent;
    border: none;
    text-align: left;
}

.choosenFieldText{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;

    overflow: hidden;
    color: --E;
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 133.333% */
}

.choosenFieldValue{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;

    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.inputedInformation{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    position: relative;

    display: block;
    width: 100%;
    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: "case" on;
    text-overflow: ellipsis;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background: transparent;
    border: none;
    text-align: left;
}

@media (max-width: 366px) {
    .inputRequestMenuDateAndTime{
        display: block;
    }

    .chooseFieldDate{
        margin-bottom: 8px;
    }
}