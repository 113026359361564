.animate__success__modal {
    position: relative;
    width: 100%;
    height: 100vh;
    /* border: 2px solid #292924; */
    overflow: hidden;
    pointer-events: none;
}

.animate__success__modal .logo__animate__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 42px 42px;
    display: flex;
    align-items: center;
    gap: 20px;
    transition: 0.2s;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animate__success__modal .logo__animate__content .text__loader img,
.animate__success__modal .logo__animate__content .text__loader .success__ani {
    /* top: 0;
    left: 0; */
    width: 70px;
    height: 70px;
}

.animate__success__modal .logo__animate__content .text__loader img.loading__image {
    position: absolute;
    transition: transform 0s;
    transform: rotate(0deg);
    animation: rot 2s linear;
    opacity: 0;
}

@keyframes rot {
    0% {
        transform: rotate(0deg);
        opacity: 1;
    }

    90% {
        transform: rotate(720deg);
        transition: 0s;
        opacity: 1;
    }

    100% {
        transform: rotate(1080deg);
        transition: transform 0s;
        opacity: 0;
    }
}

.animate__success__modal .logo__animate__content .text__loader .success__ani {
    opacity: 0;
    animation: opacity 3s linear;
    /* animation-delay: 1.5s; */
}

@keyframes opacity {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.animate__success__modal .logo__animate__content .text__loader .success__modal {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #42B069;
    transform: scale(0);
    animation: success__modal 1.5s linear;
    animation-delay: 2.5s;
    width: 100%;
    height: 100%;
    border-radius: 10rem;
}

@keyframes success__modal {
    0% {
        transform: scale(0);
    }

    20% {
        transform: scale(1);
        border-radius: 0rem;
    }

    80% {
        transform: scale(1);
        border-radius: 0rem;
    }
}