.errorBody {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
}

.errorMark {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.errorPictures {
    position: relative;
    display: flex;
    height: 12rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
}

.errored {
    position: absolute;
    right: 7rem;
    bottom: 0.4rem;
}

.errorText {
    color: var(--gray8A8A8E-B3B3B3);
    text-align: center;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem; /* 122.222% */
}

.footerErr {
    display: flex;
    position: absolute;
    flex-direction: row;

    align-items: center;

    bottom: 50px;

    width: 90%;
    /* padding: 16px; */
    height: 3rem;
}

.btnasdasdasdasdas {
    flex: 1 0 0;
    display: flex;
    padding: 4px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--white-gray);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    border: none;
    color: var(--ebanaya-xyunya);

    height: 3rem;
    text-decoration: none;
}
