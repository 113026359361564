:root[data-theme='light']{
  --background-color: #f2f2f7;
  --txt-color: #1C1C1C;
  --white-gray: #fff;
  --choosen-color: rgba(245, 73, 65, 0.10);
  --gray8A8A8E-B3B3B3: #8A8A8E;
  --redA-rebB: #F54941;
}

:root[data-theme='dark']{
  --background-color: #202020;
  --txt-color: #FFFFFF;
  --white-gray: #474747;
  --choosen-color: #BC2329;
  --gray8A8A8E-B3B3B3: #B3B3B3;
  --redA-rebB: #FF8B86;
}

.codePlaceholder{
  display: flex;
  padding: 1.5rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;
}

.titleCodePlaceholder{
  display: flex;
  padding: 1.5rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;
}

.titleCode{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.titleCodeHead{
  color: var(--txt-color);
  font-family: 'Inter', sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
}

.titleCodeValue{
  color: var(--gray8A8A8E-B3B3B3);
  font-family: 'Inter', sans-serif;
  font-feature-settings: "case" on;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.03125rem;
}

.codeValuePlaceholder{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
}

.code-input-react__digit {
    width: 45px;
    height: 45px;
    padding: 0;
    font-size: 1rem;
    text-align: center;
    margin-right: 9px;
    text-transform: uppercase;
    color: var(--txt-color);
    font-family: 'Inter', sans-serif;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    background: var(--white-gray);
    background-clip: padding-box;
  }