:root[data-theme='light']{
  --background-color: #f2f2f7;
  --txt-color: #1C1C1C;
  --white-gray: #fff;
  --choosen-color: rgba(245, 73, 65, 0.10);
  --dddxd-dxdxdxd: #fff;
  --dadataaaaa: #F54941;
}

:root[data-theme='dark']{
  --background-color: #202020;
  --txt-color: #FFFFFF;
  --white-gray: #474747;
  --choosen-color: #BC2329;
  --dddxd-dxdxdxd: #323232;
  --dadataaaaa: #FFF;
}

.btns{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* gap: 0.5rem; */
  }

  .mainBtn{
    display: flex;
    width: 100%;
    height: 48px;
    margin: 16px 0px 8px 0px;
    justify-content: center;
    align-items: center;
    /* gap: 0.5rem; */

    border-radius: 0.5rem;
    background: var(--colors-accents-brand-primary, #F54941);

    color: var(--color-base-white, #FFF);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */

    border: none;
  }

  .exitBtn{
    display: flex;
    width: 100%;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;

    border-radius: 0.5rem;
    border: 1px solid var(--white-gray);
    background: var(--white-gray);

    color: var(--dadataaaaa);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
  }

  .exitBtnIcon{
    stroke: var(--dadataaaaa);
  }
