:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --gray8A8A8E-B3B3B3: #8a8a8e;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --gray8A8A8E-B3B3B3: #b3b3b3;
}

.requestInfoContainer {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.requestInfoTitleList {
    color: var(--txt-color);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.requestInformationList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.requestInfoElement {
    display: flex;
    padding-top: 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    border-bottom: 1px solid #e3e3e3;
}

.requestInfoValueChanged {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.requestInfoValueContainer {
    display: flex;
    width: 21.4375rem;
    align-items: center;
    gap: 0.375rem;
}

.changeText {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    flex: 1 0 0;

    overflow: hidden;
    color: var(--redA-rebB);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 171.429% */
}

.requestInfoTitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    align-self: stretch;

    overflow: hidden;
    color: var(--gray8A8A8E-B3B3B3);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.requestInfoValue {
    align-self: stretch;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
