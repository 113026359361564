:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --newColor: #fff;
    --kal-kalich: #f54941;
    --blue-xdd: #007aff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --gray8A8A8E-B3B3B3: #8a8a8e;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --newColor: #323232;
    --kal-kalich: #fff;
    --blue-xdd: #78b9ff;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --gray8A8A8E-B3B3B3: #b3b3b3;
}

.settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.875rem;
    align-self: stretch;
}

.personal {
    display: flex;
    padding: 1.25rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--newColor);
}

.nameFieldPlaceholder {
    display: flex;
    height: 1.375rem;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    align-self: stretch;
}

.nameField {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
}

.nameFieldTitle {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    flex: 1 0 0;
}

.nameFieldTitleTxt {
    flex: 1 0 0;

    color: var(--txt-color);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.nameFieldValue {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.25rem;
    flex: 1 0 0;
}

.nameFieldValueTxt {
    color: var(--blue-xdd);
    font-family: Inter;
    white-space: nowrap;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.nameFieldValueDisabled {
    font-family: Inter;
    white-space: nowrap;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--gray8A8A8E-B3B3B3);
}

@media (max-width: 380px) {
    .nameFieldValueTxt {
        color: var(--blue-xdd);
        font-family: Inter;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.separator {
    margin-block-start: 0;
    margin-block-end: 0;
    height: 0px;
    align-self: stretch;
    border: 0.5px solid var(--graye3e3e3-a5a5a5);
}

/* Others */

.others {
    display: flex;
    padding: 1.25rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--newColor);
}

input.switcher[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
}

label.labelForSwitcher {
    cursor: pointer;
    text-indent: -9999px;
    width: 2.5rem;
    height: 1.375rem;
    flex-shrink: 0;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

label.labelForSwitcher:after {
    content: '';
    position: absolute;
    top: 2.1px;
    left: 3px;
    width: 16px;
    height: 17px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

input.switcher:checked + label {
    background: #34c759;
}

input.switcher:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
}

label.switcher:active:after {
    width: 15px;
}
