:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --xdxcdxxdx: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
    --blue-notgayborhood: #007aff;
    --orangeW-orangeB: #f59741;
    --greenA-greenB: #42b069;
    --redA-rebB: #f54941;
    --gray8A8A8E-B3B3B3: #8a8a8e;
    --B4B4C5-8A8A8E: #b4b4c5;
    --switcher-color: rgba(116, 116, 128, 0.08);
    --skip-buttonWhite: #2d2b2e;
    --paginationColor: #2d2b2e;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --xdxcdxxdx: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #202020;
    --grey-black: rgba(255, 255, 255, 0);
    --blue-notgayborhood: #78b9ff;
    --orangeW-orangeB: #ffac60;
    --greenA-greenB: #7fea9a;
    --redA-rebB: #ff8b86;
    --gray8A8A8E-B3B3B3: #b3b3b3;
    --B4B4C5-8A8A8E: #8a8a8e;
    --switcher-color: #323232;
    --skip-buttonWhite: #fff;
    --paginationColor: #fff;
}

* {
    /* margin: 0;
    padding: 0;
    border: 0; */
    font: inherit;
    vertical-align: baseline;
    font-family: 'SF Pro Display', 'Inter', sans-serif;
    /* outline: none; */
    transition: background-color 0.2s linear;
    /* box-sizing: border-box; */
    scroll-behavior: smooth;
    text-decoration: none;
}

body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 0.1s linear;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
input,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
hr,
video,
a {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

button,
input {
    border: none;
}

sup {
    vertical-align: text-top !important;
}

.supLowTxt {
    line-height: 12px;
}

.supMidTxt {
    line-height: 14px;
}

.supLargeTxt {
    line-height: 20px;
}

.swiper-pagination-bullet-active {
    background-color: var(--skip-buttonWhite) !important;

    opacity: 0, 2;
}

.swiper-pagination-bullet {
    background-color: var(--paginationColor) !important;

    opacity: 0, 2;
}

.swiper-pagination {
    bottom: 120px !important;
}

:root {
    --txt-malish: 11px; /* 2.5vw */
    --txt-small: 13px; /* 3vw */
    --txt-med: 15px; /* 3.5vw */
    --txt-big: 19px; /* 4vw */
    --txt-enormous: 25px; /* 4.5vw */
}

@media (max-width: 380px) {
    :root {
        --txt-malish: 9px; /* 2.5vw */
        --txt-small: 11px; /* 3vw */
        --txt-med: 13px; /* 3.5vw */
        --txt-big: 18px; /* 4vw */
        --txt-enormous: 25px; /* 4.5vw */
    }
}

@media (max-width: 347px) {
    :root {
        --txt-malish: 7px; /* 2.5vw */
        --txt-small: 10px; /* 3vw */
        --txt-med: 13px; /* 3.5vw */
        --txt-big: 17px; /* 4vw */
        --txt-enormous: 25px; /* 4.5vw */
    }
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.column {
    column-width: 100px;
    column-count: 2;
    column-gap: 10px;
    column-rule: 1px solid #ccc;
}
