:root[data-theme='light'] {
    --background-color-main: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --pizdec: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
}

:root[data-theme='dark'] {
    --background-color-main: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --pizdec: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0);
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    background: var(--background-color-main);
}

.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: var(--super-white-black);
    position: fixed;

    width: 95%;

    z-index: 99999;

    border-bottom: 1px solid var(--graye3e3e3-a5a5a5);
}

.searchField {
    display: flex;
    padding: 0.5rem 1rem 1rem 1rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
}
