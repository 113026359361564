:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC; 
    --gray8A8A8E-B3B3B3: #8A8A8E;
    --xdddd-xddddddd: #fff;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0.00);
    --gray8A8A8E-B3B3B3: #B3B3B3;
    --xdddd-xddddddd: #323232;
}

.statisticAndMap{
    display: flex;
    padding: 24px 16px 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    width: 90%;

    background: var(--xdddd-xddddddd);
}

.mapAndObjects{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

/* .mapAndObjectsPlaceholder{
    display: flex;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    justify-content: center;
    align-items: center;

    background: var(--xdddd-xddddddd);
} */

.statusPlaceholder{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.headerStatus{
    display: flex;
    padding-bottom: 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-bottom: 1px solid #e3e3e3;
}

.carContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.statusText{
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}

.statusStatistic{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

.statusHeaderText{
    display:  -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: "case" on;
    text-overflow: ellipsis;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.totalCapacity{
    color: var(--gray8A8A8E-B3B3B3);
    font-family: 'Inter', sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
}

.totalCars{
    color: var(--gray8A8A8E-B3B3B3);
    font-family: 'Inter', sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
}

.carItem{
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.stateNumber{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.capacity{
    display: flex;
    width: 3.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.destanaitonTime{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}

.carButtons{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.showOnTheMap{
    display: flex;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    border-radius: 0.5rem;
    border: 1px solid var(--graye3e3e3-a5a5a5);
}

.callButton{
    display: flex;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    border-radius: 0.5rem;
    border: 1px solid var(--graye3e3e3-a5a5a5);
}

.value{
    width: 5rem;
    color: var(--txt-color);
    font-feature-settings: "case" on;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.text{
    color: var(--gray8A8A8E-B3B3B3);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
}