:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --pizdec: #fff;
    --ebanaya-xyunya: #F54941;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --gray8A8A8E-B3B3B3: #8A8A8E;

  }
  
  :root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --pizdec: #323232;
    --ebanaya-xyunya: #FFF;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --gray8A8A8E-B3B3B3: #B3B3B3;
  }

.addInfoButton{
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.blockAutisma{
    display: flex;
    padding: 0rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.addInfoButtonTitle{
    color: var(--txt-color);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.addInfoButtonFilled{
    display: flex;
    padding: 0.625rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--fill_primary, rgba(116, 116, 128, 0.08));
}

.addInfoButtonTitleFillled{
    display: -webkit-box;
    width: 19.4375rem;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    color: var(--gray8A8A8E-B3B3B3);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 133.333% */
}

.addInfoButtonValueFillled{
    display: -webkit-box;
    width: 19.4375rem;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    color: var(--black_main, #1C1C1C);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.addTelefon{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid #e3e3e3;

    color: var(--ebanaya-xyunya);
    background: var(--white-gray);
    font-feature-settings: "case" on;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.addTelefon.disabled{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--graye3e3e3-a5a5a5);

    color: var(--ebanaya-xyunya);
    background: var(--white-gray);
    font-feature-settings: "case" on;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    opacity: 0.5;
}