:root[data-theme='light'] {
    --background-modal: rgba(227, 227, 235, 0.7);
}

:root[data-theme='dark'] {
    --background-modal: rgba(60, 60, 67, 0.4);
}

.container {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: var(--background-modal);

    animation: 100ms linear backgroundAnimation;
    animation-fill-mode: forwards;

    .redCircle {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 10000rem;
        background: #f54941;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        animation: 3s linear redCircle;
        animation-fill-mode: forwards;

        .whiteRing {
            border-radius: 10rem;
            border: 1px solid var(--base-white_main, #fff);
            opacity: 0.3;

            animation: 3s linear whiteRing;
            animation-fill-mode: forwards;
        }
        .line {
            position: fixed;
            left: 50%;
            transform: translate(-50%, 50%);
            transform: rotate(45deg);

            width: 2px;
            background: var(--base-white_main, #fff);
            border-radius: 10rem;

            animation: 3s linear line;
            animation-fill-mode: forwards;
        }
        .text {
            position: absolute;
            bottom: 30%;

            color: transparent;
            text-align: center;
            font-family: Inter;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2rem; /* 133.333% */

            animation: linear 3s text;
        }
    }
}

@keyframes backgroundAnimation {
    0% {
        background-color: transparent;
    }
    30% {
        background-color: var(--background-modal);
    }
    60% {
        background-color: var(--background-modal);
    }
    100% {
        background-color: transparent;
    }
}

@keyframes redCircle {
    0% {
        width: 0;
        height: 0;
    }

    15% {
        width: 4.375rem;
        height: 4.375rem;
    }

    30% {
        width: 150vmax;
        height: 150vmax;
        border-radius: 0;
    }
    60% {
        width: 150vmax;
        height: 150vmax;
        border-radius: 0;
        background: #f54941;
    }
    100% {
        width: 150vmax;
        height: 150vmax;
        border-radius: 0;

        background-color: transparent;
    }
}

@keyframes whiteRing {
    0% {
        height: 0;
        width: 0;
    }
    15% {
        width: 4.375rem;
        height: 4.375rem;
    }
    30% {
        width: 10rem;
        height: 10rem;
    }
    60% {
        width: 10rem;
        height: 10rem;
    }
    75% {
        width: 4.375rem;
        height: 4.375rem;
    }
    100% {
        height: 0;
        width: 0;
    }
}

@keyframes line {
    0% {
        height: 0;
    }
    15% {
        height: 2.5rem;
    }
    30% {
        height: 15.5rem;
    }
    60% {
        height: 15.5rem;
    }
    75% {
        height: 2.5rem;
    }
    100% {
        height: 0;
    }
}

@keyframes text {
    0% {
        color: transparent;
    }
    15% {
        color: transparent;
    }
    30% {
        color: var(--base-white_main, #fff);
    }

    70% {
        color: var(--base-white_main, #fff);
    }
    100% {
        color: transparent;
    }
}
