:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
    --switcher-color: rgba(116, 116, 128, 0.08);
    --gray8A8A8E-B3B3B3: #8a8a8e;
    --redA-rebB: #f54941;
    --valueBorder: #e3e3e3;
    --b3b3b3-a5a5a5: #b3b3b3;
    --searchColor: rgba(116, 116, 128, 0.08);
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0);
    --switcher-color: #323232;
    --gray8A8A8E-B3B3B3: #b3b3b3;
    --redA-rebB: #ff8b86;
    --valueBorder: #a5a5a5;
    --b3b3b3-a5a5a5: #a5a5a5;
    --searchColor: #323232;
}

.centeredDiv {
    margin-left: auto;
    margin-right: auto;
}

.classMenu {
    display: flex;
    width: 100%;
    height: 1300px;
    flex-direction: column;
    align-items: center;
    background: var(--background-color);
}

.headerRequestsSearch {
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    height: max-content;
    width: 100%;

    background: var(--super-white-black);
    border-bottom: 1px solid var(--graye3e3e3-a5a5a5);
}

.headerRequestMenu {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.headerRequestMenu span.headerTitle {
    color: var(--txt-color);
    position: relative;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
}

.searchFieldHeader {
    display: flex;
    padding: 8px 16px 16px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.searchFieldPlaceholderHeader {
    display: flex;
    padding: 7px 8px;
    align-items: center;
    flex: 1 0 0;

    border-radius: 10px;
    background: var(--searchColor);
}

.searchInputHeader {
    height: 22px;
    flex: 1 0 0;

    overflow: hidden;
    color: var(--txt-color);
    background: var(--colors-plashka-parametr, rgba(116, 116, 128, 0));
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 129.4120% */
    letter-spacing: -0.4px;
}

.backButtonSecondHeader {
    position: absolute;
    top: 24px;
}

.addressAndTime {
    display: flex;
    padding: 32px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.titleRequestMenu {
    color: var(--txt-color);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.addressAndTimeContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.buttonAddNomenclaturePlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem;
}

.nomenclatureAndCapacity {
    display: flex;
    padding: 0rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.nomenclatureAndCapacity span.titleRequestMenu {
    color: var(--txt-color);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.nomenclatureAndCapacityContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    margin-bottom: 32px;
}

.additionalMenuContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.modalSelect {
    display: flex;
    padding: 18px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;

    border-bottom: 1px solid var(--graye3e3e3-a5a5a5);
    /* border-bottom: 1px solid var(--valueBorder);
    border-top: 1px solid  var(--valueBorder);
    border-left: 1px solid  var(--valueBorder);
    border-right: 1px solid  var(--valueBorder); */

    background: var(--white-gray);
}

.modalSelect span.title {
    display: flex;
    height: 22px;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;

    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.modalSelect span.title.Active {
    display: flex;
    height: 22px;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;

    overflow: hidden;
    color: var(--b3b3b3-a5a5a5);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.modalSelect.Active {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    border-bottom: 1px solid #d0d0d0;
}

.modalSelect.Active.Opacity {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    opacity: 0.6;
    border-bottom: 1px solid #d0d0d0;
    background: var(--white-gray);
}

.modalSelect.Active span.title.Active {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;

    overflow: hidden;
    color: var(--gray8A8A8E-B3B3B3);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.modalInformationBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}

.modalInformationBlock span.selectedInformation {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;

    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.methodAndInterval {
    display: flex;
    position: relative;
    padding: 32px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.methodAndIntervalSelector {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.coneDraftButtons {
    display: flex;
    height: 44px;
    padding: 0.25rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: 0.5rem;
    border: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--switcher-color);
}

.coneDraftBtn {
    display: flex;
    padding: 0.75rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;

    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */

    background: var(--fill_primary, rgba(116, 116, 128, 0));
    border: none;
}

.coneDraftBtn.pressed {
    display: flex;
    padding: 0.75rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */

    transition: background 0.3s linear;

    border: none;

    border-radius: 0.25rem;
    background: var(--white-gray);
}

.coneDraftBtn.clicked {
    display: flex;
    padding: 0.75rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */

    transition: background 0.3s linear;

    border: none;

    border-radius: 0.25rem;
    background: var(--white-gray);
}

.coneDraftBtn.clicked2 {
    display: flex;
    padding: 0.75rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */

    transition: background 0.3s linear;

    border: none;

    border-radius: 0.25rem;
    background: var(--white-gray);
}

.coneDraftBtn.clicked3 {
    display: flex;
    padding: 0.75rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */

    transition: background 0.3s linear;

    border: none;

    border-radius: 0.25rem;
    background: var(--white-gray);
}

.coneDraftBtn.clicked4 {
    display: flex;
    padding: 0.75rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */

    transition: background 0.3s linear;

    border: none;

    border-radius: 0.25rem;
    background: var(--white-gray);
}

.coneDraftBtn.pushed {
    display: flex;
    padding: 0.75rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */

    transition: background 0.3s linear;

    border: none;

    border-radius: 0.25rem;
    background: var(--white-gray);
}

.nasosAndKran {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

/* Активаня кнопка */

.selectionMethonBtn.active {
    display: flex;
    height: 140px;
    align-items: center;
    flex: 1 0 0;

    border-radius: 8px;
    border: 1px solid var(--redA-rebB);
    background: var(--white-gray);

    overflow: hidden;
    color: var(--redA-rebB);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

/* Активаня кнопка */

.selectionMethonBtn {
    display: flex;
    height: 140px;
    align-items: center;
    padding: 0;
    flex: 1 0 0;

    border-radius: 8px;
    border: 1px solid #d0d0d000;
    background: var(--white-gray);

    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.svayaAndSamosliv {
    margin-bottom: 6px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.contactInformation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.contactInformationBlock {
    display: flex;
    padding: 0rem 1rem 1.5rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.alarmTxt {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;

    overflow: hidden;
    color: var(--redA-rebB);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}

.alarmDiv {
    display: flex;
    align-items: center;
    gap: 6px;
    align-self: stretch;
}

.create-request-menu-bottom {
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-self: stretch;
}

.addNasos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.38rem;

    width: 100%;

    color: #f54941;

    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */

    outline: none;
    height: 3rem;
    border-radius: 0.5rem;
    border: 1px solid var(--Colors-stroke);
    background: var(--Colors-btn_secondary);
}

.delete-request {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--white-gray);
}
