:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --f5f5f5: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC;
    --loaded-grey-black: rgba(203, 203, 206, 0.5);
    --gradientColor: #edeef3;
    --between-color: #d9dbe0;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --f5f5f5: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0.00);
    --loaded-grey-black: #474747;
    --gradientColor: #2c2c2c;
    --between-color: #6d6d6d;
}

.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    background: var(--background-color);
}

.today{
    display: flex;
    padding-top: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.date{
    display: flex;
    padding: 0rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.rec37{
    width: 21.4375rem;
    height: 1.625rem;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;

}

.accordeonPlaceholder{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.accordeonTitle{
    display: flex;
    padding: 0rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.docs_card_not_loaded{
    display: flex;
    height: 7.0625rem;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;

    border-radius: 0.5rem;
    border-bottom: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--f5f5f5);
}

.name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.code{
    display: flex;
    width: 19.5625rem;
    padding: 0.0625rem 0rem 0.375rem 0rem;
    align-items: center;
    gap: 1.4375rem; 
}

.rec38{
    width: 13.375rem;
    height: 1.125rem;
    flex-shrink: 0;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.poloskaFrame{
    padding: 0.25rem 0rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
}

.rec47{
    width: 9.8935rem;
    height: 0.625rem;

    margin-top: 0.63rem;
    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.rec50{
    height: 1.125rem;
    flex-shrink: 0;
    align-self: stretch;

    margin-top: 1.25rem;
    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

@keyframes AnimationGradient {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}