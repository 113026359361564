:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --redA-rebB: #F54941;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --redA-rebB: #FF8B86;
}

.heigherThanChips{
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    align-items: flex-start;
    /* gap: 0.5rem; */
    align-self: stretch;

    flex-shrink: 0;
}

.centeredDiv{
    margin-left: auto;
    margin-right: auto;
}

.chips{
    display: flex;
    width: 21.375rem;
    height: 16.75rem;

    justify-content: center;
    align-items: center;

    flex-shrink: 0;
}

.chipPlaceholder{
    display: flex;
    width: 11.375rem;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0.5rem;
    flex-shrink: 0;
    flex-wrap: wrap;
}

.chip{
    display: flex;
    width: 39%;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;

    border-radius: 0.5rem;
    background: var(--background-color);
}

.chipTxt{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;

    flex-shrink: 0;
}

.chipTxtSelected{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    color: var(--redA-rebB);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;

    flex-shrink: 0;
}

.images{
    display: flex;
    padding: 0.06081rem 1.79306rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.imagesPlaceholder{
    display: flex;
    align-items: center;
    gap: 1.5625rem;
}

.imagesGap{
    display: flex;
    align-items: center;
    gap: 1.5625rem;
}