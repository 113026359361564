:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10)
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
}


.header{
    display: flex;
    padding: 1rem;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
}

.titleCodeTxt{
    color: var(--txt-color);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem; /* 133.333% */
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
}

.exitBtn{
    position: absolute;
    right: 5px;
}