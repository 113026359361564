:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --pizdec: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --white-474747: #fff;
    --grey-black: #ececec;
    --gray8A8A8E-B3B3B3: #8a8a8e;
    --dot-color: #f54941;
}
:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --pizdec: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --white-474747: #474747;
    --grey-black: rgba(255, 255, 255, 0);
    --gray8A8A8E-B3B3B3: #b3b3b3;
    --dot-color: #f54941;
}

.datePicker {
    display: flex;
    background: var(--super-white-black);
    padding-bottom: 0px;
    justify-content: center;
    align-items: flex-start;
    gap: 0.65rem;
    align-self: stretch;

    position: relative;
}

.buttonCalendar {
    display: flex;
    width: 8px;
    height: 5px;
    margin-top: 4px;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    left: 5px;

    border-radius: 0.5rem;
    background: var(--white-474747);
    box-shadow:
        0px 1px 3px 0px rgba(63, 63, 100, 0.18),
        0px -1px 3px 0px rgba(63, 63, 100, 0.1),
        0px 0px 24px 0px rgba(63, 63, 100, 0.06);
}

.icon {
    width: 1.12619rem;
    height: 1.04144rem;
    position: absolute;
    flex-shrink: 0;
}

.hiddenDate {
    position: absolute;
    background-color: #1c1c1c00;
    color: #ececec00;
    width: 0.9rem;
    height: 0.9rem;
}

.datePicker_mini {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    width: 100%;
}

.datePicker_miniButton {
    display: flex;
    width: 3.15rem;
    height: 4rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    background-color: var(--super-white-black);
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s linear;
}

.datePicker_miniButtonCurrent {
    display: flex;
    width: 3rem;
    height: 4rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    background: var(--choosen-color);
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s linear;
}

.datePicker_miniButtonDisabled {
    display: flex;
    width: 3.15rem;
    height: 4rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    opacity: 0.5;

    background-color: var(--background-color);
    border: none;
    border-radius: 8px;
}

.weekDay {
    align-self: stretch;

    color: var(--gray8A8A8E-B3B3B3);
    text-align: center;
    font-family: Inter;
    font-size: 3.5vw;
    text-transform: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.weekNumberDay {
    align-self: stretch;

    color: var(--txt-color);
    text-align: center;
    font-family: Inter;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem; /* 128.571% */
}

.headerFocus {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: var(--super-white-black);

    width: 95%;

    border-bottom: 1px solid var(--graye3e3e3-a5a5a5);

    position: absolute;
    top: 100%;
}

.searchFieldPlaceholderHeader {
    display: flex;
    padding: 0px 12px 0px 0px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    height: 45px;
}
.searchFieldHeader {
    display: flex;
    padding: 0px 12px 0px 0px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-size: 17px;
    flex: 1 0 0;
}

.dateWithDocuments {
    position: relative;
}

.dateWithDocuments::before {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);

    width: 0.3125rem;
    height: 0.3125rem;
    background: var(--dot-color);
    border-radius: 8px;
}
