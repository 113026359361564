:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #202022;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --xdddd-xddddddd: #fff;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --xdddd-xddddddd: #323232;
}

.mainContent{
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    
    border-bottom: 1px solid #e3e3e3;
    background: var(--white-gray);
}

.menuAndInformationContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
}

.mapAndObjectsPlaceholder{
    display: flex;
    padding: 24px 16px 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    background: var(--xdddd-xddddddd);
}

.carsPlaceholder{
    display: flex;
    padding: 0px 16px 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.infoRaspredelenie{
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    background: var(--colors-background-info-zayavka, #FFF);
}