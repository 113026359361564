:root[data-theme='dark'] {
    --Colors-txt-primary: #fff;
    --Colors-btn_secondary: #474747;
    --Colors-stroke: #a5a5a5;
    --Color-Base-white: #fff;
    --Colors-plashka_choosefrom: #474747;
}

:root[data-theme='light'] {
    --Colors-txt-primary: #202020;
    --Colors-btn_secondary: #fff;
    --Colors-stroke: #e3e3e3;
    --Color-Base-white: #fff;
    --Colors-plashka_choosefrom: #fff;
}

.page {
    height: 100dvh;
}

.content {
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.block {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.addNasos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.38rem;

    color: #f54941;

    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */

    outline: none;
    height: 3rem;
    border-radius: 0.5rem;
    border: 1px solid var(--Colors-stroke);
    background: var(--Colors-btn_secondary);
}

.saveButton {
    position: fixed;
    width: calc(100% - 2rem);
    bottom: 1.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f54941;
    border-radius: 0.5rem;
    height: 3rem;
    border: none;

    color: var(--Color-Base-white);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
}

.milkSwitcher {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    color: var(--Colors-txt-primary);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.pumpsBlock {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.pumpsList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.pump {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 3.75rem;
    border-radius: 0.5rem;
    border-bottom: 1px solid var(--Colors-stroke);
    background: var(--Colors-plashka_choosefrom);

    overflow: hidden;
    color: var(--Colors-txt-primary, #202020);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.pumpHeader {
    overflow: hidden;
    color: var(--Colors-txt-secondary, #8a8a8e);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 133.333% */
}

.pumpInfo {
    display: flex;
    flex-direction: column;
}
