:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10)
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
}

.contentContainer{
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.contentFields{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.contentTicket{
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    border-bottom: 1px solid #d0d0d0;
    background: var(--white-gray);
    /* transform: translateX(-150%);
    animation: animation 1s forwards;
    animation-iteration-count: 1 */
}

.contentTicket span.contentTxt{
    flex: 1 0 0;

    color: var(--txt-color);
    font-feature-settings: "case" on;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

/* @keyframes animation {
    0% {transform: translateX(-150%);}
    100% {transform: translateX(0);}
  } */