:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
}

.datePlaceholder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.dateTextPlaceholder {
    display: flex;
    padding-bottom: 0px;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
}

.dateText {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.text {
    color: var(--txt-color);
    font-family: Inter;
    font-size: var(--txt-big);
    font-style: normal;
    font-weight: 700;
    line-height: 1rem; /* 133.333% */
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
}

.dateLine {
    height: 0.1px;
    width: 100%;
    align-self: stretch;
    border-radius: 4px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    background-color: #f54941;
    border: 1px solid #f54941;
}

.date {
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}
