:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
    --blue-notgayborhood: #007aff;
    --orangeW-orangeB: #f59741;
    --greenA-greenB: #42b069;
    --redA-rebB: #f54941;
    --gray8A8A8E-B3B3B3: #8a8a8e;
    --B4B4C5-8A8A8E: #b4b4c5;
    --switcher-color: rgba(116, 116, 128, 0.08);
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0);
    --blue-notgayborhood: #78b9ff;
    --orangeW-orangeB: #ffac60;
    --greenA-greenB: #7fea9a;
    --redA-rebB: #ff8b86;
    --gray8A8A8E-B3B3B3: #b3b3b3;
    --B4B4C5-8A8A8E: #8a8a8e;
    --switcher-color: #323232;
}

.footerCreateRequest {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    position: absolute;
    margin-top: auto;
}

.footerSubmitButton {
    display: flex;
    padding: 0px 16px;
    flex-direction: row;
    /* width: 100%; */
    align-items: flex-end;
    align-self: stretch;

    gap: 10px;
}

.submitButton {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
    box-sizing: border-box;
    align-self: stretch;

    border-radius: 0.5rem;
    background: #a9a9a9;
    color: #fff;
    font-feature-settings: 'case' on;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    border: none;

    opacity: 0.5;
}

.submitButton.active {
    background: #f54941;
    color: #fff;
    opacity: 1;
}

.errorFieldCreate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    display: flex;

    justify-content: center;

    overflow: hidden;
    color: var(--redA-rebB);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    margin-bottom: 6px;
}

.delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    background-color: var(--super-white-black);
    border-radius: 8px;
    border: 1px solid var(--graye3e3e3-a5a5a5);
    flex-shrink: 0;
}
