:root[data-theme='dark'] {
    --Colors-txt-secondary: #8a8a8e;
    --Colors-txt-primary: #fff;
}

:root[data-theme='light'] {
    --Colors-txt-secondary: #8a8a8e;
    --Colors-txt-primary: #202020;
}

.page {
    height: 100dvh;
    width: 100%;
    box-sizing: border-box;
}

.content {
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.header {
    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem; /* 128.571% */
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
}

.subHeader {
    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.block {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.buttons {
    position: fixed;
    width: calc(100% - 2rem);
    bottom: 1.5rem;
    display: flex;
    gap: 0.5rem;
}

.saveButton {
    display: flex;
    flex: 1 0;
    justify-content: center;
    align-items: center;
    background-color: #f54941;
    border-radius: 0.5rem;
    height: 3rem;
    border: none;

    color: var(--Color-Base-white, #fff);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
}

.selectLength {
    outline: none;
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
    max-height: 3.75rem;
    border-radius: 0.5rem;
    border-bottom: 1px solid var(--Colors-stroke, #e3e3e3);
    background: var(--Colors-btn_secondary, #fff);
}

.select,
.placeholder {
    width: 100%;
    outline: none;
    background-color: var(--Colors-btn_secondary, #fff);
    border: none;
    overflow: hidden;
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 100% */
}

.length,
.select {
    color: var(--Colors-txt-primary, #202020);
}

.placeholder {
    color: var(--Colors-txt-secondary, #8a8a8e);
}

.size {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.checkbox {
    display: flex;
    justify-content: space-between;
}

.trash {
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 1px solid var(--Colors-stroke, #e3e3e3);
    background: var(--base-white_main, #fff);
}

.pipeAndTube {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
