:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
    --blue-notgayborhood: #007aff;
    --orangeW-orangeB: #f59741;
    --greenA-greenB: #42b069;
    --redA-rebB: #f54941;
    --f54941-474747: #f54941;
    --gray8A8A8E-B3B3B3: #8a8a8e;
    --switcher-color: rgba(116, 116, 128, 0.08);
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0);
    --blue-notgayborhood: #78b9ff;
    --orangeW-orangeB: #ffac60;
    --greenA-greenB: #7fea9a;
    --redA-rebB: #ff8b86;
    --f54941-474747: #fff;
    --gray8A8A8E-B3B3B3: #b3b3b3;
    --switcher-color: #323232;
}

.content {
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
}

.firstBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.m3info {
    display: flex;
    padding: 0.4375rem 4.6875rem 0.45731rem 1.23031rem;
    align-items: center;
    gap: 1.41444rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--kal-kalich2);
}

.txt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.0625rem;

    width: 250px;
}

.capacityOverall {
    color: var(--txt-color);
    font-family: Inter;
    font-size: 1.675rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 2.4375rem */
}

.txtMonthInfo {
    display: flex;
    padding-left: 0px;
    align-items: flex-start;
    gap: 0.625rem;
}

.txtMonth {
    color: var(--txt-color);
    font-family: Inter;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 0.975rem */
}

.thirdBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.statisticCount {
    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 0.975rem */
    text-transform: uppercase;
    .date {
        color: var(--blue-notgayborhood);
    }
}

.add_info {
    display: flex;
    padding: 14px 20px;
    align-items: center;
    gap: 14px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--kal-kalich2);
}

.add_infoTxtPlaceholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    flex: 1 0 0;
}

.add_infoTxtPlaceholderTxt1 {
    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
}

.add_infoTxtPlaceholderTxt2 {
    color: var(--gray8A8A8E-B3B3B3);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
}

.secondBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.headTxt {
    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
    text-transform: uppercase;
}

.add_infoCertificate {
    display: flex;
    padding: 7px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    z-index: 99999;

    border-radius: 8px;
    background: var(--white-gray);
}

.infoCertificate {
    display: flex;
    align-items: center;
    gap: 20px;
    align-self: stretch;
}

.images {
    display: flex;
    width: 100px;
    align-items: center;
    gap: 4.5px;
}

.txtInfo {
    flex: 1 0 0;

    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
}

.cards {
    display: flex;
    align-items: center;
    gap: 13px;
    flex-wrap: wrap;
    align-self: stretch;
}

.fourthBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.plashkas {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.saved {
    display: flex;
    padding: 14px 20px 14px 16px;
    align-items: center;
    gap: 24px;

    width: 50%;

    border-radius: 8px;
    background: var(--kal-kalich2);
}

.contentPlaska {
    display: flex;
    align-items: center;
    gap: 12px;
}

.contentTxt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.collectedTxt {
    align-self: stretch;

    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
}

.collectedValuePlaceholder {
    display: flex;
    align-items: center;
    gap: 6px;
    align-self: stretch;
}

.collectedValueTxt {
    color: var(--txt-color);
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
}

.transfered {
    display: flex;
    padding: 14px 20px 14px 16px;
    align-items: center;
    gap: 24px;
    height: 50px;
    /* flex: 1 0 0; */

    width: 50%;

    border-radius: 8px;
    background: var(--kal-kalich2);
}

.monthTxt {
    color: var(--blue-notgayborhood);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
    text-transform: uppercase;
}

.myGifts {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--white-gray);

    color: var(--f54941-474747);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
}

.calendar_btn {
    display: flex;
    align-items: center;
    gap: 6px;
    align-self: stretch;
}

.statisticPlaceholder {
    display: flex;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
}

.svgPlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterButton {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    position: relative;

    border-radius: 8px;
    border: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--white-gray);
}

@media (max-width: 375px) {
    .plashkas {
        display: block;
        align-items: center;
        gap: 12px;
        align-self: stretch;
    }

    .saved {
        display: flex;
        padding: 14px 20px 14px 16px;
        align-items: center;
        gap: 24px;

        width: 89%;
        border-radius: 8px;
        background: var(--kal-kalich2);
    }

    .transfered {
        display: flex;
        padding: 14px 20px 14px 16px;
        align-items: center;
        gap: 24px;
        height: 50px;
        flex: 1 0 0;
        width: 89%;

        border-radius: 8px;
        background: var(--kal-kalich2);

        margin-top: 5px;
    }
}
