:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --pizdec: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --gray8A8A8E-B3B3B3: #8a8a8e;
}

:root[data-theme='dark'] {
    --background-color: #1c1c1c;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --pizdec: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --gray8A8A8E-B3B3B3: #b3b3b3;
}

.accordeonPlaceholder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.accordeonPosition {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.accordeon {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;

    background: var(--pizdec);
    border-radius: 0.5rem;
    border-bottom: 1px solid var(--colors-stroke, #e3e3e3);
}

.order {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.namePlaceholder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.nameForUPD {
    display: flex;
    width: 100%;
    flex-direction: flex-start;
    align-items: flex-start;
    flex: 1 0 0;
}

.name {
    display: flex;
    /* width: 19.4375rem; */
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}

.nomenclature {
    align-self: stretch;

    color: var(--txt-color);
    font-family: Inter;
    font-size: var(--txt-big);
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
}

.address {
    align-self: stretch;

    color: var(--gray8A8A8E-B3B3B3);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.separator {
    margin-block-start: 0;
    margin-block-end: 0;
    height: 0.01px;
    width: 100%;
    align-self: stretch;
    border: 0.5px dashed var(--graye3e3e3-a5a5a5);
    margin-top: 0.5em;
    margin-bottom: 0.15em;
}

.qty {
    display: flex;
    padding-top: 12px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 6.125rem;
    width: 100%;
}

.txt {
    display: flex;
    width: 100%;
    align-items: center;
}

.race {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: max-content;
    flex: 1 0 0;
}

.raceNotFit {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: 25%;
}

.racesText {
    color: var(--gray8A8A8E-B3B3B3);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.racesValue {
    color: var(--txt-color);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.amount {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 0.25rem;
    flex: 1 0 0;
}

.amountText {
    color: var(--gray8A8A8E-B3B3B3);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 150% */
}

.amountValue {
    color: var(--txt-color);
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.arrow {
    display: flex;
    width: 1.125rem;
    height: 1.125rem;
    padding: 0.25rem 0rem 0.1925rem 0rem;
    justify-content: center;
    align-items: center;
}

.totalPlaceholder {
    margin-top: 16px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.twoPlashkas {
    display: flex;
    width: 100%;
    gap: 8px;
    align-self: stretch;
}

.baseTwo {
    display: flex;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;

    border-radius: 0.5rem;
    background: var(--grayf6f6f6-474747);
    color: #1c1c1c !important;
    text-decoration: none !important;
}

.base {
    display: flex;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: var(--grayf6f6f6-474747);
    color: #1c1c1c !important;
    text-decoration: none !important;
}

.hiddenLink {
    color: #1c1c1c !important;
    text-decoration: none !important;
    width: 100%;
    height: 100%;
}

.baseTxt {
    display: flex;
    flex: 1 0 0;

    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 1.2rem; /* 150% */
}

.downloadBtn {
    display: flex;
    width: 1rem;
    height: 1rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    border-radius: 0.5rem;
    border: 1px solid #e3e3e3;
    background: var(--white-gray);
}

.arrowImg {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.10713rem 0.28044rem 0.09144rem 0.26788rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}
