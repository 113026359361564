:root[data-theme='light']{
    --background-color: #f2f2f7;
    --fff-202020: #fff;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC;
    --blue-notgayborhood: #007AFF;
    --orangeW-orangeB: #F59741;
    --greenA-greenB: #42B069;
    --redA-rebB: #F54941;
    --gray8A8A8E-B3B3B3: #8A8A8E;
    --switcher-color: rgba(116, 116, 128, 0.08);
}

:root[data-theme='dark']{
    --background-color: #202020;
    --fff-202020: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0.00);
    --blue-notgayborhood: #78B9FF;
    --orangeW-orangeB: #FFAC60;
    --greenA-greenB: #7FEA9A;
    --redA-rebB: #FF8B86;
    --gray8A8A8E-B3B3B3: #B3B3B3;
    --switcher-color: #323232;
}

.content{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.chips{
    display: flex;
    align-items: center;
    gap: 10px;
}

.chipActive{
    display: flex;
    padding: 6px 12px 6px 14px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    border: 1px solid var(--redA-rebB);
    background: var(--white-gray);
}

.chip{
    display: flex;
    padding: 6px 12px 6px 14px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    border-radius: 8px;
    border: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--white-gray);
}

.chipTxt{
    color: var(--txt-color);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

.num{
    display: flex;
    padding: 0px 7px 1px 7px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    border-radius: 12px;
    border: 1px solid var(--colors-stroke-dark, #A5A5A5);
}

.numTxt{
    color: var(--txt-color);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 180% */
}

.certificates{
    display: flex;
    /* padding: 12px 20px 14px 20px; */
    align-items: center;
    flex-direction: column;
    gap: 14px;
    align-self: stretch;

}

.certificatesSecondGrp{
    display: flex;
    /* padding: 12px 20px 14px 20px; */
    align-items: center;
    flex-direction: column;
    gap: 14px;
    align-self: stretch;

}

.certificateSecondGrp{
    display: flex;
    padding: 12px 20px 14px 20px;
    align-items: center;
    gap: 14px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--white-gray);
}

.certificate{
    display: flex;
    padding: 12px 14px 14px 14px;
    align-items: center;
    gap: 14px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--white-gray);
}

.certificateContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}

.info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.infoTxt{
    align-self: stretch;

    color: var(--txt-color);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
}

.infoPlaceholder{
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.certificateInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.sum{
    display: flex;
    align-items: center;
    gap: 6px;
    align-self: stretch;
}

.sumTxt{
    color: var(--txt-color);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 13px */
}

.sumValue{
    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 14.3px */
}

.activate{
    display: flex;
    align-items: center;
    gap: 10px;
}

.activateTxt{
    color: var(--txt-color);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 13px */
}

.code{
    display: flex;
    padding: 8px 12px 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 11px;

    border-radius: 6px;
    border: 1px solid var(--graye3e3e3-a5a5a5);
}

.codeTxt{
    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 14.3px */
}

.usedCerticficateTxt{
    align-self: stretch;

    color: var(--gray8A8A8E-B3B3B3);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 15.6px */
}

.usedSumTxt{
    color: var(--gray8A8A8E-B3B3B3);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 13px */
}

.usedSumValue{
    color: var(--gray8A8A8E-B3B3B3);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 13px */
}

.lateActivation{
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch; 
}

.lateActivationTxtPlaceholder{
    display: flex;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
}

.lateActivationTxt{
    color: var(--redA-rebB);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 14.3px */
}

.contentImg{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;

    margin-top: 50%;
}

.noCerTxt{
    color: #8A8A9F;
    text-align: center;
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}