/* root[] {
    --Colors-Accents-brand-primary:  
} */

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100vw;
    color: var(--txt-color);

    * {
        box-sizing: border-box;
        outline: none;
    }

    .header {
        display: flex;
        justify-content: flex-end;
        padding: 0.875rem 1rem;
        align-items: center;

        span {
            color: var(--txt-color);
            font-family: Inter;
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1.75rem; /* 155.556% */
            letter-spacing: -0.025rem;
        }
    }

    .slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100vw;
        flex-grow: 1;
        padding-bottom: 1rem;

        &.slide1 {
            padding-top: 1.25rem;
            gap: 1.125rem;
        }
        &.slide2 {
            padding-top: 4rem;
            gap: 4rem;
        }
        &.slide3 {
            padding-top: 1.5625rem;
            gap: 1.875rem;
        }
        &.slide4 {
            align-items: flex-start;
            gap: 3.125rem;
            padding-top: 2.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            .usageManual {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 2.5rem;

                .element {
                    display: flex;
                    gap: 1rem;

                    .icon {
                        flex-shrink: 0;
                    }

                    span {
                        font-family: Inter;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 130%; /* 1.3rem */
                        letter-spacing: -0.025rem;
                    }
                }
            }
        }
        .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
        }
        .head {
            text-align: center;
            font-family: Inter;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 1.5rem */
            letter-spacing: -0.025rem;
        }
        .text {
            max-width: 75%;
            text-align: center;
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 1.3rem */
            letter-spacing: -0.025rem;
        }
    }

    .footer {
        z-index: 1;
        position: sticky;
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 100%;
        padding-bottom: 1.5rem;
        bottom: 0;
        background-color: var(--background-color);

        .buttons {
            display: flex;
            width: 100%;
            padding: 0.75rem 1rem;
            align-items: center;
            gap: 0.75rem;

            .prevBtn {
                display: flex;
                padding: 0.75rem 1.5rem;
                justify-content: center;
                align-items: center;
                border-radius: 0.5rem;
                border: 1px solid var(--graye3e3e3-a5a5a5, #e3e3e3);
                background: var(--xdxcdxxdx, #fff);
                color: #f54941;
                span {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.5rem; /* 150% */
                }
            }

            .nextBtn {
                display: flex;
                padding: 0.75rem 1.5rem;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
                flex: 1 0 0;
                border-radius: 0.5rem;
                background: #f54941;

                span {
                    color: var(--Color-Base-white, #fff);
                    font-feature-settings: 'case' on;
                    font-family: Inter;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.5rem; /* 150% */
                }
            }
        }
    }
}
