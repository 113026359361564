.content{
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
}

.generalPush{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
}

input.switcher[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
}

input.switcher2[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
}

label.labelForSwitcher {
    cursor: pointer;
    text-indent: -9999px;
    width: 2.5rem;
    height: 1.375rem;
    flex-shrink: 0;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

label.labelForSwitcher2 {
    cursor: pointer;
    text-indent: -9999px;
    width: 2.5rem;
    height: 1.375rem;
    flex-shrink: 0;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

label.labelForSwitcher:after {
    content: '';
    position: absolute;
    top: 2.1px;
    left: 3px;
    width: 16px;
    height: 17px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

label.labelForSwitcher2:after {
    content: '';
    position: absolute;
    top: 2.1px;
    left: 3px;
    width: 16px;
    height: 17px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

input.switcher:checked + label {
    background: #34c759;
}

input.switcher2:checked + label {
    background: #34c759;
}

input.switcher:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
}

input.switcher2:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
}

label.switcher:active:after {
    width: 15px;
}

label.switcher2:active:after {
    width: 15px;
}

.txt{
    flex: 1 0 0;

    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.general_push{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.general_push_placeholder{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}