:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --w123123123: rgba(245, 73, 65, 0.1);
    --selected-date: rgb(255, 255, 255);
    --range-days: #fff2f0;
    --disabled-days: #ccc;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --w123123123: #f96760;
    --selected-date: #202020;
    --range-days: #444444;
    --disabled-days: #616161;
}

.react-datepicker-wrapper {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    flex: 1 0 0 !important;
}

.react-datepicker__input-container {
    position: none !important ;
    display: flex !important;
    /* width: 100% !important; */
}

.react-datepicker__portal {
    right: 0 !important;
}

.react-datepicker__tab-loop {
    display: flex !important;
    position: absolute;
}

.react-datepicker-popper {
    /* padding-top: -100px !important; */
    /* transform: translate3d(auto, 298px, 0px) !important; */
    align-items: center !important;
    /* inset: 5px auto 5px auto !important; */
}
.react-datepicker {
    font-size: 15px !important;
    border: none !important;
    background: var(--white-gray) !important;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1) !important;
}

/* .react-datepicker__month-container{
    width: 80% !important; 
    height: 80% !important;
} */

.react-datepicker__month-container {
    padding: 18px;
}

.react-datepicker__header {
    background-color: var(--white-gray) !important;
    border: none !important;
    border-bottom: none !important;
}

.react-datepicker__day-names {
    color: var(--txt-color) !important;
    text-align: center !important;
    font-feature-settings:
        'clig' off,
        'liga' off !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 0.75rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    /* line-height: 1rem !important; */
    margin-top: 7px !important;
    margin-bottom: -22px !important;

    border: none;
}

.react-datepicker__day-name {
    margin: 0.5rem !important;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
    display: none;
}

.react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0.25rem !important;
}

.react-datepicker__navigation.react-datepicker__navigation--next {
    right: 0 !important;
    margin-top: 18px !important;
    /* background: url(../../../../svg/Vector\ 2.png) no-repeat !important;
    width: 0.40625rem;
    height: 0.875rem;
    stroke-width: 2px;
    stroke: #464649; */
    margin-right: 8% !important;
}

.react-datepicker__navigation.react-datepicker__navigation--previous {
    left: 0 !important;
    margin-top: 18px !important;
    /* margin-bottom: auto !important; */
    /* background: url(../../../../svg/Vector\ 3.png) no-repeat !important;
    width: 0.40625rem;
    height: 0.875rem;
    stroke-width: 2px;
    stroke: #464649; */
    margin-left: 8%;
}

/* .react-datepicker__navigation-icon{
    display: none;
} */

.react-datepicker__month {
    margin: 0.05rem 0.05rem 0.05rem 0.05rem !important;
}

.react-datepicker__day {
    margin: 0.5rem !important;
}

.react-datepicker__current-month {
    /* font-family: 'Inter', sans-serif !important; */
    color: var(--txt-color) !important;
    font-feature-settings:
        'clig' off,
        'liga' off;
    font-family: 'Poppins', sans-serif !important;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 133.333% */
    letter-spacing: 0.02375rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: var(--txt-color) !important;
}
.react-datepicker__day--disabled {
    color: #b3b3b3 !important;
}

.react-datepicker__current-month::first-letter {
    text-transform: uppercase;
}

.react-datepicker__day--today {
    font-weight: normal !important;
}

.react-datepicker__month {
    text-transform: uppercase !important;
    font-family: 'Poppins', sans-serif !important;
}

.react-datepicker__month {
    font-family: 'Poppins', sans-serif !important;
}

.react-datepicker__day.react-datepicker__day--selected {
    background-color: var(--w123123123) !important;
    color: var(--txt-color) !important;
}

.react-datepicker__time {
    background: var(--white-gray) !important;
}

.react-datepicker__time-container {
    font-family: 'Poppins', sans-serif !important;
}

.react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--txt-color);
    font-weight: normal;
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
    background-color: var(--w123123123) !important;
    color: var(--txt-color) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: var(--w123123123) !important;
    color: var(--txt-color) !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: #ffffff00 !important;
}

.react-datepicker__header {
    background-color: var(--background-color);
}

/*  */

.react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only {
    display: none !important;
}

.react-datepicker__time-list-item--selected {
    color: var(--w123123123) !important;
}

.react-datepicker.react-datepicker--time-only {
    margin-right: 0.45rem !important;
    margin-left: 0.45rem !important;
    height: 300px !important;
    border-radius: 14px !important;
    border: none;
}
.react-datepicker__time-container {
    border-radius: 0.5rem !important;
    width: 9.5rem !important;
    height: 300px !important;
}

.react-datepicker__triangle {
    display: none !important;
}

.react-datepicker__time-box {
    width: auto !important;
    height: 300px !important;
}

.react-datepicker__time {
    width: auto !important;
    border-radius: 0.5rem !important;
    height: 300px !important;
}

.react-datepicker__time-list {
    height: 300px !important;
}

@media (max-width: 366px) {
    .react-datepicker__time-box {
        width: 88vw !important;
    }

    .react-datepicker__time {
        width: 88vw !important;
    }
}

/* dateselector in bonuses */

.period-select-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
    box-shadow: none !important;
    background-color: var(--selected-date) !important;
    .react-datepicker__day--outside-month {
        pointer-events: none !important;
    }
    padding-bottom: 64px;
    .react-datepicker__day--outside-month::before {
        content: '';
        position: absolute;
        background-color: var(--selected-date) !important;
        height: 43px !important;
        width: 43px !important;
        pointer-events: none !important;
        z-index: 0 !important;
    }

    .react-datepicker__month-container {
        padding: 0;
        background-color: var(--selected-date);
    }

    .react-datepicker__week {
        display: grid;
        grid-template-columns: repeat(7, 42px);
        height: 42px;
    }

    .react-datepicker__day-name {
        display: none;
    }

    .react-datepicker__month {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .react-datepicker__day--in-range {
        background-color: #00000000;
        color: 'white' !important;
        height: 42px;
        width: 42px;
        z-index: 1;
        margin: 0 !important;
    }

    .react-datepicker__day--in-range::before {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: var(--range-days);
        height: 30px;
        width: 42px;
    }

    .react-datepicker__day--range-end {
        color: 'white' !important;
        height: 42px !important;
        width: 42px !important;
    }
    .react-datepicker__day--range-end::before {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: #f96760;
        border-radius: 10px;
        height: 42px;
        width: 42px;
    }

    .react-datepicker__day {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .react-datepicker__day--selected {
        content: '';
        background-color: #f96760 !important;
        border-radius: 10px;
        height: 42px;
        width: 42px;
        margin: 0 !important;
    }

    .react-datepicker__day--in-selecting-range {
        position: relative;
        z-index: 2;
        background-color: #c9443d00 !important;
        border-radius: 10px;
        height: 42px;
        width: 42px;
        margin: 0 !important;
    }

    .react-datepicker__day--disabled {
        color: var(--disabled-days) !important;
    }

    .react-datepicker__aria-live {
        display: none;
    }

    .react-datepicker__navigation {
        display: none;
    }

    .react-datepicker__current-month {
        margin-bottom: 16px;
        margin-left: 10px;
        text-align: start;
    }
    .react-datepicker__day--001 {
        background-color: var(--selected-date) !important;
    }

    .react-datepicker__current-month,
    .react-datepicker__header {
        background-color: var(--selected-date) !important;
    }
    .react-datepicker__day:hover,
    .react-datepicker__day--keyboard-selected {
        background-color: #00000000;
    }
    .react-datepicker__day--range-start {
        color: 'white' !important;
        height: 42px !important;
        width: 42px !important;
    }

    .react-datepicker__day--range-start::before {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: #f96760;
        border-radius: 10px;
        height: 42px;
        width: 42px;
    }

    .react-datepicker__day--selecting-range-start {
        background-color: #f96760 !important;
    }
}
