.modal{
    position: fixed;
    width: 100%;
    bottom: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__content{
    position: absolute;
    display: flex;
    height: 40%;
    padding: 2rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    flex-shrink: 0;
    border-radius: 0.5rem 0,5rem 0rem 0rem;
    width: 100%;
    bottom: 0;
    background-color: #fff;
}

.modal__header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    justify-content: center;
}

.modal__body{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;

    color: #8a8a8e;
    font-family: 'Inter', sans-serif;
    text-align: center;
    font-feature-settings: "case" on;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.modal__title{
    color: #1c1c1c;
    font-family: 'Inter', sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
}

.modal__footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
}

.modal__button{
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: #f54941;

    color: #fff;
    font-feature-settings: "case" on;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    border: none;
}

/* .modal__content{
    transform: translateY(-150%);
    animation: animation 1s forwards;
  }
  
  @keyframes animation {
    0% {transform: translateY(150%);}
    100% {transform: translateY(0);}
  } */