:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC; 
    --switcher-color: rgba(116, 116, 128, 0.08);
    --gray8A8A8E-B3B3B3: #8A8A8E;
    --redA-rebB: #F54941;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0.00);
    --switcher-color: #323232;
    --gray8A8A8E-B3B3B3: #B3B3B3;
    --redA-rebB: #FF8B86;
}

.placeholder{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    border-radius: 8px;
}

.position{
    display: flex;
    height: 60px;
    padding: 0px 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--white-gray);
}

.txtPlaceholder{
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
}

.txts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}

.label{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;

    overflow: hidden;
    color: var(--gray8A8A8E-B3B3B3);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.value{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;

    overflow: hidden;
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border: none;

    background: var(--gra);
}

.option{
    background: var(--white-gray);
}

/* option:checked  { background-color: var(--redA-rebB); } */
option:hover  { background-color: var(--redA-rebB); }
