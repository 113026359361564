:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC;
    --blue-notgayborhood: #007AFF;
    --orangeW-orangeB: #F59741;
    --greenA-greenB: #42B069;
    --redA-rebB: #F54941;
    --gray8A8A8E-B3B3B3: #8A8A8E;
    --B4B4C5-8A8A8E: #B4B4C5;
    --switcher-color: rgba(116, 116, 128, 0.08);
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #202020;
    --grey-black: rgba(255, 255, 255, 0.00);
    --blue-notgayborhood: #78B9FF;
    --orangeW-orangeB: #FFAC60;
    --greenA-greenB: #7FEA9A;
    --redA-rebB: #FF8B86;
    --gray8A8A8E-B3B3B3: #B3B3B3;
    --B4B4C5-8A8A8E: #8A8A8E;
    --switcher-color: #323232;
}

.pie_chart{
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
    height: 140px;

    border-radius: 8px;
    background: var(--kal-kalich2);

    position: relative;
}

.chartContainer{
    display: flex;
    align-items: center;
    flex: 1 0 0;
}

.chart_info{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    flex: 1 0 0;
    position: absolute;
    right: 10px;
    top: 30px;
}

.chart_infoPlaceholder{
    display: flex;
    align-items: flex-start;
    align-self: stretch;
}

.class{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    flex: 1 0 0;
    margin-right: 23px;
}

.m3{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    margin-right: 23px;
}

.percent{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
}

.subhead{
    display: flex;
    align-items: center;
    gap: 6px;
}

.subheadTxt{
    color: var(--gray8A8A8E-B3B3B3);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
}

.elWithPic{
    display: flex;
    align-items: center;
    gap: 6px;
    align-self: stretch;
}

.elWithPicTxt{
    color: var(--txt-color);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 10.565px; 88.038% */
}

@media (max-width: 404px) {
    .chart_info{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        flex: 1 0 0;
        position: absolute;
        right: 15px;
        top: 21px;
    }
}

@media (max-width: 335px) {
    .chart_info{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        flex: 1 0 0;
        position: absolute;
        right: 6px;
        top: 21px;
    }
}

@media (max-width: 320px) {
    .chart_info{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        flex: 1 0 0;
        position: absolute;
        right: 1px;
        top: 21px;
    }
}

@media (min-width: 355px) {
    .chart_info{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        flex: 1 0 0;
        position: absolute;
        right: 28px;
        top: 21px;
    }
}

@media (min-width: 373px) {
    .chart_info{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        flex: 1 0 0;
        position: absolute;
        right: 25px;
        top: 21px;
    }
}