:root[data-theme='light'] {
    --Colors-plashka_parametr: rgba(116, 116, 128, 0.08);
    --txt-color: #202020;
}

:root[data-theme='dark'] {
    --Colors-plashka_parametr: #474747;
    --txt-color: #ffffff;
}

.title {
    width: calc((100% - 32px) / 4);
    height: 24px;
    display: flex;
    border-radius: 30px;
    background: var(--Colors-plashka_parametr);
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: var(--txt-color);
    cursor: pointer;
}
