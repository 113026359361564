:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --xdddd-xddddddd: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC; 
    --gray8A8A8E-B3B3B3: #8A8A8E;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --xdddd-xddddddd: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0.00);
    --gray8A8A8E-B3B3B3: #B3B3B3;
}

.content{
    display: flex;
    padding: 24px 16px 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    background: var(--xdddd-xddddddd);
}

.upSide{
    display: flex;
    padding: 8px 32px;
    flex-direction: column;
    align-items: center;

    border-radius: 40px;
    border: 1px solid var(--colors-accents-green-status, #42B069);
}

.requestlaceholder{
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.requestTxt{
    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.lowSide{
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--graye3e3e3-a5a5a5);
}

.column{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
}

.txt{
    color: var(--colors-txt-secondary, #8A8A8E);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

.value{
    color: var(--txt-color);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
}

.line{
    width: 1px;
    align-self: stretch;

    background: var(--colors-stroke, #E3E3E3);
}