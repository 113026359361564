:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --gray8A8A8E-B3B3B3: #8A8A8E;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --gray8A8A8E-B3B3B3: #B3B3B3;
}

.infoBlock{
    padding: 32px 16px 16px 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

@keyframes ani {
    0% {transform: translateX(550%);}
    100% {transform: translateX(0);}
  }

.requestStatusHeader{
    display: flex;
    padding-bottom: 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.statusText span.statusTitle{
    color: var(--txt-color);
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.statusText{
    display: flex;
    align-items: center;
    gap: 4px;
}

.statusText span{
    color: var(--txt-color);
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.requestStatusHeader span{
    color: var(--gray8A8A8E-B3B3B3);
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.requestStatusHeader.line{
    height: 0.1px;
    width: 98.5%;
    align-self: stretch;
    border-radius: 4px;
    background-color: #A5A5A5;
    border: 1px solid #A5A5A5;
}

.capacityInfo{
    display: flex;
    padding: 12px 0px;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
}

.capacityBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
}

hr.infoBlockLine{
    width: 1px;
    align-self: stretch;
    background: #dbdbdb;
}

.capacityBlock span.capacityTitle{
    color: var(--gray8A8A8E-B3B3B3);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.capacityBlock span.capacityValue{
    color: var(--txt-color);
    font-family: 'Inter', sans-serif;
    font-size: var(--txt-med);
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}