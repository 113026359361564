:root[data-theme='light'] {
    --Colors-stroke: #e3e3e3;
    --Colors-tapbar: #fff;
    --Colors-txt-primary: #202020;
    --Text-secondary: #5b5b5b;
    --Colors-btn_secondary: #fff;
    --Colors-plashka_parametr: rgba(116, 116, 128, 0.08);
    --Text-non-active: #8a8b8c;
    --Button-secondary-normal: #fff;
    --Colors-background: #f2f2f7;
    --Colors-btn_main_disabled: #d0d0d2;
    --Colors-txt_btn_main_disabled: #fff;
    --Button-primary-normal: #1e1e1e;
}

:root[data-theme='dark'] {
    --Colors-stroke: #a5a5a5;
    --Colors-tapbar: #202020;
    --Colors-txt-primary: #ffffff;
    --Text-secondary: #cdced2;
    --Colors-btn_secondary: #474747;
    --Colors-plashka_parametr: #323232;
    --Text-non-active: #8a8b8c;
    --Button-secondary-normal: #4d5157;
    --Colors-background: #202020;
    --Colors-btn_main_disabled: #8a8a8e;
    --Colors-txt_btn_main_disabled: #fff;
    --Button-primary-normal: #ffffff;
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    * {
        box-sizing: border-box;
    }

    .header {
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        padding: 1rem;
        justify-content: space-between;
        align-items: flex-start;

        border-bottom: 1px solid var(--Colors-stroke, #e3e3e3);
        background: var(--Colors-tapbar, #fff);

        .icon {
            width: 1.5rem;
            height: 1.5rem;
            transform: rotate(180deg);
        }

        span {
            color: var(--Colors-txt-primary, #202020);
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem; /* 150% */
        }
    }

    .content {
        padding-top: 3.5rem;
        padding-bottom: 5rem;
        .heading {
            color: var(--Text-secondary, #5b5b5b);
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            line-height: 1rem; /* 114.286% */
        }

        .nomenclature {
            box-sizing: border-box;

            padding: 1.5rem 1rem 0.75rem 1rem;

            color: var(--Colors-txt-primary, #202020);
            font-family: Inter;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.5rem; /* 150% */
        }

        .chips {
            display: flex;
            padding: 1rem;
            flex-direction: column;
            gap: 1rem;

            .chipList {
                display: flex;
                max-width: 100%;
                overflow: auto;
                gap: 0.5rem;

                .baseChip {
                    transition: border-color 0.2s;
                    padding: 0.375rem 0.75rem 0.375rem 0.875rem;
                    gap: 0.625rem;
                    border-radius: 0.5rem;
                    border: 1px solid var(--Colors-stroke, #e3e3e3);
                    background: var(--Colors-btn_secondary, #fff);

                    white-space: nowrap;

                    color: var(--Colors-txt-primary, #202020);
                    font-family: Inter;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.25rem; /* 125% */

                    &.chipActive {
                        border: 1px solid var(--Colors-Accents-red_btn_mini, #f54941);
                    }
                }
            }

            .textareaContainer {
                display: flex;
                width: 100%;
                padding: 0.75rem;
                align-items: flex-start;
                align-self: stretch;
                border-radius: 0.75rem;
                background: var(--Colors-plashka_parametr, #fff);
            }
            .textarea {
                width: 100%;
                outline: none;
                border: none;
                min-height: 1.375rem;
                background-color: transparent;
                color: var(--Colors-txt-primary, #202020);
                font-family: Inter;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.375rem;
                max-height: 7.5rem;
                overflow: auto;

                &::placeholder {
                    color: var(--Text-non-active, #8a8b8c);
                    font-family: Inter;
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.375rem; /* 122.222% */
                }
            }
        }

        .routes {
            display: flex;
            padding: 1.5rem 1rem;
            flex-direction: column;
            gap: 0.75rem;

            .routeList {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: 0.5rem;
            }

            .routeChip {
                display: flex;
                height: 3.375rem;
                padding: 0.5rem;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                flex-shrink: 0;
                width: 5.0625rem;

                border-radius: 0.75rem;
                border: 1px solid #b3b3b3;
                background: var(--Colors-tapbar);

                .plus {
                    color: var(--Colors-txt-primary, #202020);
                    font-feature-settings: 'case' on;
                    font-family: Inter;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .title {
                    color: var(--Colors-txt-secondary, #8a8a8e);
                    text-align: center;
                    font-family: Inter;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .number {
                    white-space: nowrap;
                    color: var(--Colors-txt-primary, #202020);
                    font-feature-settings: 'case' on;
                    font-family: Inter;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 1.225rem */
                }
            }
        }

        .files {
            display: flex;
            padding: 1rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            align-self: stretch;

            .fileHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1.5rem;
                align-self: stretch;

                .headingWithIcon {
                    display: flex;
                    padding: 0.5rem 0rem;
                    align-items: center;
                    gap: 0.625rem;
                    flex-grow: 1;
                }

                .input {
                    display: flex;
                    height: 2.625rem;
                    padding: 0.75rem 0.875rem;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0.75rem;
                    border: 1px solid var(--Strokes-bold, #cdced2);
                    background: var(--Button-secondary-normal, #fff);

                    span {
                        color: var(--Colors-txt-primary, #252627);
                        font-family: Inter;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1rem;
                    }
                    .fileInput {
                        width: 0;
                        height: 0;
                    }
                }
            }

            .fileList {
                display: flex;
                align-items: flex-start;
                align-content: flex-start;
                gap: 0.5rem;
                align-self: stretch;
                flex-wrap: wrap;

                .fileChip {
                    display: flex;
                    max-width: 100%;
                    padding: 0.75rem 0.875rem 0.75rem 1.125rem;
                    justify-content: space-between;
                    align-items: center;
                    flex: 1 0 0;
                    border-radius: 0.75rem;
                    border: 1px solid var(--Strokes-bold, #cdced2);
                    background: var(--Button-secondary-normal, #fff);

                    .titleWithIcon {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        max-width: 90%;

                        .fileName {
                            max-width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: var(--Colors-txt-primary, #252627);
                            font-family: Inter;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1rem; /* 114.286% */
                        }
                    }

                    .icon {
                        flex-shrink: 0;
                    }
                }
            }
        }
    }

    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        background: var(--Colors-background, #f2f2f7);
        padding: 1rem;
        height: 5rem;

        .submitButton {
            transition: background 0.3s;
            width: 100%;
            display: flex;
            padding: 0.75rem 1.5rem;
            height: min-content;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            border-radius: 0.5rem;
            background: var(--Colors-btn_main_disabled, #d0d0d2);

            color: var(--Colors-txt_btn_main_disabled, #fff);
            font-feature-settings: 'case' on;
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem; /* 150% */

            &.submitButtonActive {
                background: var(--Colors-Accents-brand-primary, #f54941);
            }
        }
    }
}

.modal {
    display: flex;
    flex-direction: column;

    .heading {
        display: flex;
        padding: 1.25rem 1.5rem 1rem 1.5rem;
        align-items: center;

        color: var(--Colors-txt-primary, #202020);
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1rem;
    }

    .route {
        display: flex;
        padding: 1rem 1.5rem;
        justify-content: space-between;
        gap: 0.625rem;

        color: var(--Colors-txt-primary, #202020);
        font-family: Inter;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem; /* 122.222% */

        .checkboxWithTime {
            display: flex;
            gap: 1.5rem;
        }
    }

    .footer {
        display: flex;
        padding: 1rem 1.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        .whiteButton {
            display: flex;
            padding: 0.75rem 0.875rem 0.75rem 1.125rem;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            flex: 1 0 0;
            border-radius: 0.75rem;
            border: 1px solid var(--Strokes-bold, #cdced2);
            background: var(--Button-secondary-normal, #fff);

            color: var(--Colors-txt-primary);
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem; /* 125% */
        }

        .blackButton {
            display: flex;
            padding: 0.75rem 0.875rem;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;
            flex: 1 0 0;
            border-radius: 0.75rem;
            background: var(--Button-primary-normal, #1e1e1e);
            color: var(--Colors-background, #fff);
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem; /* 125% */
        }
    }
}
