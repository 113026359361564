:root[data-theme='light'] {
    --background-color: #f2f2f7;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --f5f5f5: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ececec;
    --loaded-grey-black: rgba(203, 203, 206, 0.5);
    --gradientColor: #edeef3;
    --between-color: #d9dbe0;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --f5f5f5: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0);
    --loaded-grey-black: #474747;
    --gradientColor: #2c2c2c;
    --between-color: #6d6d6d;
}

.modalBody {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;

    background: var(--background-color);
}

.contentContainer {
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.contentFields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.contentTicket {
    display: flex;
    padding: 16px;
    align-items: center;
    height: 23px;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    border-bottom: 1px solid #d0d0d0;
    background: linear-gradient(
        90deg,
        var(--loaded-grey-black),
        var(--between-color),
        var(--gradientColor)
    );
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.contentTicketBig {
    display: flex;
    padding: 16px;
    align-items: center;
    height: 40px;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    border-bottom: 1px solid #d0d0d0;
    background: linear-gradient(
        90deg,
        var(--loaded-grey-black),
        var(--between-color),
        var(--gradientColor)
    );
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.contentTicketDoubleSpan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}

.nameContentTxt {
    align-self: stretch;

    color: var(--txt-color);
    font-feature-settings: 'case' on;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

@keyframes AnimationGradient {
    0% {
        background-position: 0% 51%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 51%;
    }
}
