:root[data-theme='light']{
    --background-color: #f2f2f7;
    --fff-202020: #fff;
    --txt-color: #202020;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --kal-kalich2: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC;
    --blue-notgayborhood: #007AFF;
    --orangeW-orangeB: #F59741;
    --greenA-greenB: #42B069;
    --redA-rebB: #F54941;
    --gray8A8A8E-B3B3B3: #8A8A8E;
    --switcher-color: rgba(116, 116, 128, 0.08);
}

:root[data-theme='dark']{
    --background-color: #202020;
    --fff-202020: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --kal-kalich2: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0.00);
    --blue-notgayborhood: #78B9FF;
    --orangeW-orangeB: #FFAC60;
    --greenA-greenB: #7FEA9A;
    --redA-rebB: #FF8B86;
    --gray8A8A8E-B3B3B3: #B3B3B3;
    --switcher-color: #323232;
}

.tabs{
    display: flex;
    align-items: flex-start;
    margin-left: 8px;
    margin-right: 1px;
    top: 85px;
    z-index: 9999;

    width: 105% !important;

    transform: translateX(-150%);
    animation: ani 450ms forwards;

    /* background: var(--super-white-black); */
}

.card_status{
    display: flex;
    padding: 6px 1px 6px 1px;
    justify-content: center;
    align-items: center;
    gap: 9%;

    border-radius: 8px;
    border: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--white-gray);
}

.card_statusHidden{
    width: 0px !important;

    display: flex !important;
}

.card_statusActive{
    display: flex;
    padding: 6px 1px 6px 1px;
    justify-content: center;
    align-items: center;
    gap: 9%;

    border-radius: 8px;
    border: 1px solid var(--redA-rebB);
    background: var(--white-gray);
}

.card_statusTxt{
    color: var(--txt-color);
    font-family: Inter;
    font-size: var(--txt-small);
    font-style: normal;
    text-align: center;
    justify-content: center;
    font-weight: 500;
    line-height: 12px; /* 150% */
}

.card_statusCount{
    display: flex;
    padding: 0px 6px 1px 6px;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    border-radius: 12px;
    border: 1px solid var(--colors-stroke-dark, #A5A5A5);

    /* position: relative; */
}

.card_statusCountTxt{
    color: var(--txt-color);
    /* position: absolute; */
    /* margin-right: 0.5px; */
    text-align: center;
    font-family: Inter;
    font-size: var(--txt-small);
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 180% */
}
  
  @keyframes ani {
    0% {transform: translateX(-650%);}
    100% {transform: translateY(0);}
  }