:root[data-theme='light'] {
    --background-color: #ffffff;
    --txt-color: #1c1c1c;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.1);
    --pizdec: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --white-474747: #fff;
    --grey-black: #ececec;
    --gray8A8A8E-B3B3B3: #8a8a8e;
}

:root[data-theme='dark'] {
    --background-color: #202020;
    --txt-color: #ffffff;
    --white-gray: #474747;
    --choosen-color: #bc2329;
    --pizdec: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --white-474747: #474747;
    --grey-black: rgba(255, 255, 255, 0);
    --gray8A8A8E-B3B3B3: #b3b3b3;
}

.periodSelect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 20%;
    background-color: var(--super-white-black);
}

.periodSelectTabs {
    position: sticky;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 16px;
}

.daysOfWeek {
    display: grid;
    grid-template-columns: repeat(7, 42px);
    border-top: 1px solid var(--gray8A8A8E-B3B3B3);
    border-bottom: 1px solid var(--gray8A8A8E-B3B3B3);
}

.day {
    font-family: Inter;
    color: var(--gray8A8A8E-B3B3B3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.top {
    width: 100%;
    background-color: var(--super-white-black);
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 56px;
    justify-content: center;
    align-items: center;
    z-index: 10;
    max-width: 330px;
}

.period {
    color: var(--txt-color);
    box-sizing: border-box;
    width: 100%;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    padding-left: 29px;
    padding-bottom: 16px;
    .current {
        font-weight: 600;
    }
}

.button {
    display: flex;
    width: 100%;
    height: 48px;
    justify-content: center;
    align-items: center;
    background-color: #f54941;
    border-radius: 8px;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
}

.bottom {
    z-index: 10;
    padding-left: 25px;
    padding-right: 25px;
    background-color: var(--super-white-black);
    position: fixed;
    bottom: 0;
    height: 90px;
    width: 88%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exitX {
    position: fixed;

    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10000;
}
