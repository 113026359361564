.page {
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.blockContainer {
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;

    border-radius: 0.5rem;
    background: #fff;
}

.txt {
    display: flex;
    height: 3rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.125rem;
}

.totalSum {
    align-self: stretch;
    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.headerTxt {
    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 184.615% */
}

.lowerTxt {
    color: var(--Colors-progress-time_txt, #8a8a8e);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.66031rem; /* 88.038% */
}

.amount {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    align-self: stretch;
}

.amountTxt {
    color: var(--Colors-txt-primary, #202020);
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 1.95rem */
}

.firstBlockContainer {
    display: flex;
    align-items: center;
    gap: 5%;
    align-self: stretch;
}
