:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --f5f5f5: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC;
    --loaded-grey-black: rgba(203, 203, 206, 0.5);
    --gradientColor: #edeef3;
    --between-color: #d9dbe0;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --f5f5f5: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0.00);
    --loaded-grey-black: #474747;
    --gradientColor: #2c2c2c;
    --between-color: #6d6d6d;
}

/* background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
background-size: 400% 400%;
animation: AnimationGradient 2500ms ease infinite; */

.block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.content{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    align-self: stretch;
}

.smallLineHeader{
    width: 170px;
    height: 16px;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.Docs_card_not_loadedFirst{
    display: flex;
    height: 85px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--f5f5f5);
}

.name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.code{
    display: flex;
    width: 313px;
    height: 30.5px;
    align-items: center;
    gap: 23px;
}

.codeLine{
    width: 40%;
    flex-shrink: 0;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.namePlaceholder{
    display: flex;
    width: 100%;
    height: 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.namePlaceholderLine{
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.Docs_card_not_loadedSecond{
    display: flex;
    height: 163px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--f5f5f5);
}

.chartContainer{
    display: flex;
    width: 100%;
    align-items: center;
    flex: 1 0 0;
}

.chartArea{
    display: flex;
    padding: 12px;
    align-items: center;
}

.chart_info{
    display: flex;
    height: 101px;
    padding: 0px 15px 0px 19px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
}

.chart_infoPlaceholder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.chartInfoLine{
    height: 16px;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.chartPie{
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.Docs_card_not_loadedThird{
    display: flex;
    height: 66px;
    padding: 14px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--f5f5f5);
}

.nameSecond{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
}

.nameSecondPlaceholder{
    display: flex;
    width: 100%;
    height: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px; 
}

.nameSecondPlaceholderLine{
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.codeSecond{
    display: flex;
    width: 100%;
    height: 16px;
    align-items: center;
    gap: 23px;
}

.codeSecondLine{
    width: 145px;
    flex-shrink: 0;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.secondBlockLine{
    width: 106px;
    height: 16px;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.Docs_card_not_loadedFourth{
    display: flex;
    height: 140px;
    padding: 14px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--f5f5f5);
}

.nameSecondBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
}

.nameSecondBlockPlaceholder{
    display: flex;
    height: 64px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.nameSecondBlockPlaceholderBigLine{
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.codeSecondBlock{
    display: flex;
    height: 28px;
    align-items: center;
    gap: 23px;
    align-self: stretch;
}

.codeSecondBlockLine{
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.pieChartInfoSecond{
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--f5f5f5);
}

.pieChartInfoSecondPlaceholder{
    display: flex;
    height: 143px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.pieChartSvg{
    display: flex;
    width: 143px;
    height: 143px;
    padding: 0px 1px 1px 0px;
    justify-content: center;
    align-items: center;
}

.chartPieSecond{
    width: 142px;
    height: 142px;
    flex-shrink: 0;

    position: relative;
}

.txtSecondBlock{
    display: flex;
    width: 68px;
    height: 41px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;

    top: 55px;
    left: 36px;

    position: absolute;
}

.ballsChart{
    height: 16px;
    flex-shrink: 0;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.ballsChartSecond{
    height: 20px;
    flex-shrink: 0;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.chartInfoSecond{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
}

.left_to_save{
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
}

.txtPlaceholder{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
}

.txtArea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    align-self: stretch;
}

.chartInfoSecondLine{
    height: 16px;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.chartInfoSecondLineSecond{
    height: 28px;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.pieChartButton{
    height: 48px;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.thirdBlock{
    display: flex;
    width: 188px;
    height: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.thirdBlockLine{
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.thirdBlockLine{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.twoBlocksThird{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.thirdBlockFirstSection{
    display: flex;
    height: 105px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.thirdBlockSecondSection{
    display: flex;
    height: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.fourthBlockLine{
    display: flex;
    width: 92px;
    height: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.plashkas{
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.saved{
    display: flex;
    padding: 14px 20px 14px 16px;
    align-items: center;
    gap: 24px;

    border-radius: 8px;
    background: var(--f5f5f5);
}

.contentFirst{
    display: flex;
    width: 139px;
    align-items: center;
    gap: 12px;
}

.txtFourth{
    display: flex;
    height: 41px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex: 1 0 0;
}

.upperLine{
    height: 16px;
    flex-shrink: 0;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.lowerLine{
    height: 20px;
    flex-shrink: 0;
    align-self: stretch;

    border-radius: 8px;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.transfered{
    display: flex;
    padding: 14px 20px 14px 16px;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;

    border-radius: 8px;
    background: var(--f5f5f5);
}

.contentSecond{
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
}

@keyframes AnimationGradient {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}