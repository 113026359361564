:root[data-theme='light']{
    --background-color: #f2f2f7;
    --txt-color: #1C1C1C;
    --white-gray: #fff;
    --choosen-color: rgba(245, 73, 65, 0.10);
    --f5f5f5: #fff;
    --graye3e3e3-a5a5a5: #e3e3e3;
    --graycecece-d0d0d2: #cecece;
    --super-white-black: #fff;
    --grey-black: #ECECEC;
    --loaded-grey-black: rgba(203, 203, 206, 0.5);
    --gradientColor: #edeef3;
    --between-color: #d9dbe0;
}

:root[data-theme='dark']{
    --background-color: #202020;
    --txt-color: #FFFFFF;
    --white-gray: #474747;
    --choosen-color: #BC2329;
    --f5f5f5: #323232;
    --graye3e3e3-a5a5a5: #a5a5a5;
    --graycecece-d0d0d2: #d0d0d2;
    --super-white-black: #1c1c1c;
    --grey-black: rgba(255, 255, 255, 0.00);
    --loaded-grey-black: #474747;
    --gradientColor: #2c2c2c;
    --between-color: #6d6d6d;
}

.header{
    background-color: var(--super-white-black);
    position: fixed;

    width: 100%;
    height: var(--heightValue);
    border-bottom: 1px solid var(--graye3e3e3-a5a5a5);
    z-index: 9999;
}

.firstGroup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;

    background: var(--background-color);
}

.cardCategory{
    display: flex;
    padding: 1.5rem 1rem 0rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
}

.Cards_group_head_not_loaded{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
}
 
.cardHeader{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
}

.cardContent{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5625rem;
    align-self: stretch;
}

.bigLine{
    height: 1.75rem;
    flex: 1 0 0;

    border-radius: 0.5rem;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.plashkas{
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 8px;
    margin-left: 15px;
}

.plashkasLine{
    width: 113px;
    height: 29px;
    flex-shrink: 0;
    align-self: stretch;

    border-radius: 0.5rem;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.smallLine{
    width: 3.514rem;
    height: 1.75rem;
    
    border-radius: 0.5rem;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.contentLine{
    height: 3.75rem;
    flex: 1 0 0;
    
    border-radius: 0.5rem;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;}

.cards{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.card{
    display: flex;
    padding: 0rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.Request_card_not_loaded{
    display: flex;
    height: 8rem;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.0625rem;
    align-self: stretch;
    
    border-radius: 0.5rem;
    border-bottom: 1px solid var(--graye3e3e3-a5a5a5);
    background: var(--f5f5f5);
}

.name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.code{
    display: flex;
    width: 19.5625rem;
    padding-bottom: 0px;
    align-items: center;
    gap: 1.4375rem;
}

.cardHeaderLine{
    width: 13.375rem;
    height: 1.125rem;
    flex-shrink: 0;
    
    border-radius: 0.5rem;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;
}

.cardHeaderLineSmall{
    height: 0.75rem;
    flex: 1 0 0;

    border-radius: 0.5rem;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;}

.underCode{
    display: flex;
    padding: 0.1875rem 0rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
}

.underCodeLine{
    width: 9.8935rem;
    height: 0.625rem;
    
    margin-top: 0.83rem;
    
    border-radius: 0.5rem;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;}

.mainContnetLine{
    height: 2.25rem;
    flex-shrink: 0;
    align-self: stretch;
    
    margin-top: 1.06rem;
    margin-bottom: 1.06rem;
    
    border-radius: 0.5rem;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;}

.footerLine{
    height: 0.5625rem;
    flex-shrink: 0;
    align-self: stretch;
    
    border-radius: 0.5rem;
    background: linear-gradient(90deg, var(--loaded-grey-black), var(--between-color), var(--gradientColor));
    background-size: 400% 400%;
    animation: AnimationGradient 2500ms ease infinite;}

@keyframes AnimationGradient {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}