.switch {
    cursor: pointer;
    text-indent: -9999px;
    width: 2.5rem;
    height: 1.375rem;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50px;
}

.slider:before {
    position: absolute;
    content: '';
    top: 2.1px;
    left: 3px;
    width: 16px;
    height: 17px;
    background: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background: #34c759;
}

input:checked + .slider:before {
    left: calc(100% - 3px);
    transform: translateX(-100%);
}
