:root {
    --Black-black-100: #3a3c40;
    --Black-black-200: #2f3237;
    --Black-black-300: #222428;
    --Black-black-400: #252627;
    --Black-black-500: #1e1e1e;
    --Blue-blue-100: #eef4fa;
    --Blue-blue-200: #e3ebf6;
    --Blue-blue-300: #d5e4fa;
    --Blue-blue-400: #b4cff6;
    --Blue-blue-500: #85aee9;
    --Blue-blue-600: #007aff;
    --Blue-blue-700: #35598e;
    --ColdGray-cold-gray-100: #edf1f3;
    --ColdGray-cold-gray-200: #ebeef2;
    --ColdGray-cold-gray-300: #e4eef3;
    --ColdGray-cold-gray-400: #d8e6ee;
    --ColdGray-cold-gray-500: #93979b;
    --ColdGray-cold-gray-600: #4d5157;
    --ColdGray-cold-gray-700: #3f4852;
    --ColdGray-cold-gray-800: #41444a;
    --Green-green-100: #f1faf2;
    --Green-green-200: #d6f4de;
    --Green-green-300: #97e1aa;
    --Green-green-400: #71f693;
    --Green-green-500: #34c759;
    --Green-green-600: #0b882a;
    --Green-green-700: #004311;
    --LightGreen-light-green-100: #dced74;
    --LightGreen-light-green-200: #b5d438;
    --LightGreen-light-green-300: #82af00;
    --LightGreen-light-green-400: #739c00;
    --Orange-orange-100: #fff9ed;
    --Orange-orange-200: #ffeedc;
    --Orange-orange-300: #f8d7b2;
    --Orange-orange-400: #fabc76;
    --Orange-orange-500: #f6aa54;
    --Orange-orange-600: #d77200;
    --Orange-orange-700: #b86506;
    --Purple-purple-50: #fcdef3;
    --Purple-purple-100: #d7daf8;
    --Purple-purple-200: #e2d2f6;
    --Purple-purple-300: #c1aed8;
    --Purple-purple-400: #979cd4;
    --Purple-purple-500: #565a8a;
    --Red-red-100: #fbe5e5;
    --Red-red-200: #e7acac;
    --Red-red-300: #ff7575;
    --Red-red-400: #ff3d3d;
    --Red-red-500: #dd444d;
    --Red-red-600: #d82727;
    --Red-red-700: #d8355c;
    --Turquoise-turq-100: #d8ebee;
    --Turquoise-turq-600: #347b80;
    --Warm-Gray-warm-gray-100: #f7f7f7;
    --Warm-Gray-warm-gray-200: #f2f2f2;
    --Warm-Gray-warm-gray-300: #eaeaea;
    --Warm-Gray-warm-gray-400: #d0d2d3;
    --Warm-Gray-warm-gray-500: #cdced2;
    --Warm-Gray-warm-gray-600: #b3b3b3;
    --Warm-Gray-warm-gray-700: #8a8b8c;
    --Warm-Gray-warm-gray-800: #5b5b5b;
    --white: #ffffff;
    --Color_background: #fdfdff;
    --Color_card: #ffffff;
    --Color_graphic_blue: #6493fe;
    --Color_graphic_green: #12cca6;
    --Color_graphic_grey: #959595;
    --Color_graphic_green_bright: #ebed8b;
    --Color_graphic_light-green: #a8e8db;
    --Color_graphic_light-grey: #959595;
    --Color_sigment: #f2f2f7;
    --Color_new_sigment: #e8e8ee;
    --Color_tabbar: #f7f7f7;
    --Color_text_green: #4fa494;
    --Color_text_primary: #252627;
    --Color_text_primary2: #5b5b5b;
    --Color_text_red: #ff3226;
}
:root[data-theme='dark'] {
    --Accents-Blue-accent: #35598e;
    --Accents-Blue-highlight: #35598e;
    --Accents-Blue-icon: #eef4fa;
    --Accents-Blue-link: #85aee9;
    --Accents-Green-accent: #34c759;
    --Accents-Green-secondary: #d6f4de;
    --Accents-Green-text: #97e1aa;
    --Accents-Orange-accent: #f6aa54;
    --Accents-Orange-drivers: #d77200;
    --Accents-Orange-text: #fabc76;
    --Accents-Purple-fishka: #d7daf8;
    --Accents-Purple-progress-bar_accent: #979cd4;
    --Accents-Purple-progress-bar_secondary: #d7daf8;
    --Accents-Red-accent: #dd444d;
    --Accents-Red-attention: #ff7575;
    --Accents-Red-icon: #ff7575;
    --Accents-Red-icon_2: #fbe5e5;
    --Accents-Red-plashka: #d8355c;
    --Accents-Red-text: #ff7575;
    --Backs-auxiliary-elements: #2f3237;
    --Backs-back-beton: #222428;
    --Backs-background: #1e1e1e;
    --Backs-info-plashka: #2f3237;
    --Backs-modal: #1e1e1e;
    --Backs-secondary-plashka: #2f3237;
    --Backs-tapbar-modal: #252627;
    --Backs-tapbar: #222428;
    --Backs-time-taken: #1e1e1e;
    --Button-back-clients-btn: #3f4852;
    --Button-back-datepicker: #2f3237;
    --Button-chips-client-btn: #2f3237;
    --Button-chips-info: #41444a;
    --Button-chips-shosen: #ffffff;
    --Button-chips: #3a3c40;
    --Button-knopka_plashka: #41444a;
    --Button-primary-active: #ebeef2;
    --Button-primary-disabled: #8a8b8c;
    --Button-primary-normal: #ffffff;
    --Button-requests_plashka: #3a3c40;
    --Button-round-small: #e3ebf6;
    --Button-secondary-active: #f2f2f2;
    --Button-secondary-disabled: #ffffff;
    --Button-secondary-normal: #4d5157;
    --Button-shadow-active: #4d5157;
    --Button-shadow-disabled: #93979b;
    --Button-shadow-normal: #4d5157;
    --Button-tap-bar-active: #ffffff;
    --Button-tap-bar-inactive: #b3b3b3;
    --Button-toggle_inactive: #b3b3b3;
    --Icon-circle_btn_arrow: #222428;
    --Icon-disabled_dark: #cdced2;
    --Icon-disabled_light: #b3b3b3;
    --Icon-non-active: #8a8b8c;
    --Icon-normal-main: #ffffff;
    --Icon-normal-secondary: #ffffff;
    --Icon-normal-white: #2f3237;
    --Icon-placeholder: #4d5157;
    --Mascot-blue: #85aee9;
    --Mascot-green: #34c759;
    --Mascot-orange: #fabc76;
    --Mascot-purple: #c1aed8;
    --Mascot-tongue: #ff7575;
    --Other-base-gray: #93979b;
    --Other-grabber: rgba(255, 255, 255, 0.3);
    --Other-shadow: rgba(191, 191, 191, 0.04);
    --Other-shadow_overlay: rgba(75, 77, 80, 0.62);
    --Progress-bar-progress-bar_back: #edf1f3;
    --Progress-bar-progress-bar_main: #ffffff;
    --Progress-bar-progress-circle-mini_back: #93979b;
    --Progress-bar-progress-circle-mini_main: #d5e4fa;
    --Request-Raspr-icon: #f6aa54;
    --Request-Raspr-stroke: #fabc76;
    --Request-Raspr-txt: #fabc76;
    --Request-V_puti-icon: #dced74;
    --Request-V_puti-stroke: #dced74;
    --Request-V_puti-txt: #dced74;
    --Request-Vigruzka-icon: #71f693;
    --Request-Vigruzka-stroke: #97e1aa;
    --Request-Vigruzka-txt: #71f693;
    --Request-gotovi: #f8d7b2;
    --Request-green_txt: #004311;
    --Request-ne_zayavili: #d5e4fa;
    --Request-orange_stroke: #f8d7b2;
    --Request-orange_txt: #b86506;
    --Request-otgruzeno: #97e1aa;
    --Request-plan: #2f3237;
    --Request-plan_stroke: #eaeaea;
    --Status-active: #d6f4de;
    --Status-lost: #eaeaea;
    --Status-norequest: #ffeedc;
    --Status-perspective: #d5e4fa;
    --Strokes-base: #b3b3b3;
    --Strokes-bold: #4d5157;
    --Strokes-dark: #b3b3b3;
    --Strokes-illustration: #b3b3b3;
    --Strokes-mini-btns: #41444a;
    --Text-base: #ffffff;
    --Text-disabled: #4d5157;
    --Text-non-active: #93979b;
    --Text-placeholder: #93979b;
    --Text-secondary: #cdced2;
    --Text-secondary2: #ffffff;
    --Tags-blue: #35598e;
    --Tags-green: #34c759;
    --Tags-orange: #f6aa54;
    --Tags-purple: #979cd4;
    --Tags-red: #ff7575;
    --Tags-turquoise: #347b80;
}
:root[data-theme='light'] {
    --Accents-Blue-accent: #35598e;
    --Accents-Blue-highlight: #e3ebf6;
    --Accents-Blue-icon: #35598e;
    --Accents-Blue-link: #35598e;
    --Accents-Green-accent: #34c759;
    --Accents-Green-secondary: #d6f4de;
    --Accents-Green-text: #0b882a;
    --Accents-Orange-accent: #fabc76;
    --Accents-Orange-driver: #f8d7b2;
    --Accents-Orange-text: #d77200;
    --Accents-Purple-fishka: #e2d2f6;
    --Accents-Purple-progress-bar_accent: #979cd4;
    --Accents-Purple-progress-bar_secondary: #d7daf8;
    --Accents-Red-accent: #ff3d3d;
    --Accents-Red-attention: #ff3d3d;
    --Accents-Red-icon: #dd444d;
    --Accents-Red-icon_2: #ff3d3d;
    --Accents-Red-plashka: #fbe5e5;
    --Accents-Red-text: #d82727;
    --Backs-auxiliary-elements: #ebeef2;
    --Backs-back-beton: #edf1f3;
    --Backs-background: #f7f7f7;
    --Backs-info-plashka: #ffffff;
    --Backs-modal: #ffffff;
    --Backs-secondary-plashka: #edf1f3;
    --Backs-tapbar-modal: #ffffff;
    --Backs-tapbar: #ffffff;
    --Backs-time-taken: #edf1f3;
    --Button-back-clients-btn: #d8e6ee;
    --Button-back-datepicker: #d8e6ee;
    --Button-chips-client-btn: #ffffff;
    --Button-chips-info: #ffffff;
    --Button-chips-shosen: #1e1e1e;
    --Button-chips: #ffffff;
    --Button-knopka_plashka: #ffffff;
    --Button-primary-active: #3a3c40;
    --Button-primary-disabled: #8a8b8c;
    --Button-primary-normal: #1e1e1e;
    --Button-requests_plashka: #ffffff;
    --Button-round-small: #ffffff;
    --Button-secondary-active: #f2f2f2;
    --Button-secondary-disabled: #ffffff;
    --Button-secondary-normal: #ffffff;
    --Button-shadow-active: #f2f2f2;
    --Button-shadow-disabled: #f2f2f2;
    --Button-shadow-normal: #ffffff;
    --Button-tap-bar-active: #1e1e1e;
    --Button-tap-bar-inactive: #b3b3b3;
    --Button-toggle_inactive: #d0d2d3;
    --Icon-circle_btn_arrow: #2f3237;
    --Icon-disabled_dark: #cdced2;
    --Icon-disabled_light: #b3b3b3;
    --Icon-non-active: #8a8b8c;
    --Icon-normal-main: #2f3237;
    --Icon-normal-secondary: #5b5b5b;
    --Icon-normal-white: #ffffff;
    --Icon-placeholder: #ebeef2;
    --Mascot-blue: #b4cff6;
    --Mascot-green: #97e1aa;
    --Mascot-orange: #f8d7b2;
    --Mascot-purple: #c1aed8;
    --Mascot-tongue: #e7acac;
    --Other-base-gray: #93979b;
    --Other-grabber: rgba(60, 60, 67, 0.3);
    --Other-shadow: rgba(68, 83, 113, 0.05);
    --Other-shadow_overlay: rgba(56, 69, 79, 0.25);
    --Progress-bar-progress-bar_back: #ebeef2;
    --Progress-bar-progress-bar_main: #1e1e1e;
    --Progress-bar-progress-circle-mini_back: #ffffff;
    --Progress-bar-progress-circle-mini_main: #1e1e1e;
    --Request-Raspr-icon: #f6aa54;
    --Request-Raspr-stroke: #fabc76;
    --Request-Raspr-txt: #d77200;
    --Request-V_puti-icon: #82af00;
    --Request-V_puti-stroke: #b5d438;
    --Request-V_puti-txt: #739c00;
    --Request-Vigruzka-icon: #0b882a;
    --Request-Vigruzka-stroke: #34c759;
    --Request-Vigruzka-txt: #0b882a;
    --Request-gotovi: #ffeedc;
    --Request-green_txt: #0b882a;
    --Request-ne_zayavili: #e3ebf6;
    --Request-orange_stroke: #f6aa54;
    --Request-orange_txt: #d77200;
    --Request-otgruzeno: #d6f4de;
    --Request-plan: #ffffff;
    --Request-plan_stroke: #41444a;
    --Status-active: #f1faf2;
    --Status-lost: #f2f2f2;
    --Status-norequest: #fff9ed;
    --Status-perspective: #e3ebf6;
    --Strokes-base: #eaeaea;
    --Strokes-bold: #cdced2;
    --Strokes-dark: #cdced2;
    --Strokes-illustration: #5b5b5b;
    --Strokes-mini-btns: #f2f2f2;
    --Text-base: #252627;
    --Text-disabled: #d0d2d3;
    --Text-non-active: #8a8b8c;
    --Text-placeholder: #cdced2;
    --Text-secondary: #5b5b5b;
    --Text-secondary2: #5b5b5b;
    --Text-white: #ffffff;
    --Color_new_sigment: #e8e8ee;
    --Tags-blue: var(--Blue-blue-300);
    --Tags-green: var(--Green-green-200);
    --Tags-orange: var(--Orange-orange-300);
    --Tags-purple: var(--Purple-purple-200);
    --Tags-red: var(--Red-red-100);
    --Tags-turquoise: var(--Turquoise-turq-100);
}
